import React, { useEffect, useState, memo } from 'react'
import classnames from 'classnames'
import useDimensions from 'react-use-dimensions'
import {
  VideoWrapper,
  ImageWrapper,
  BackgroundContainer,
  ArticleImageWrapper
} from './background.styled'
import gridStyles from './background.module.scss'
import getImgixUrl from '../../utils/getImgixUrl'
import Picture from './pictureWrapper'
import VideoBackground from './VideoBackground'
import { getBrightcoveVideoId } from '../../utils/common'

const BackgroundCover = (props) => {
  const [ref, { width: containerWidth, height: containerHeight }] =
    useDimensions()

  const [containerSize, setContainerSize] = useState({
    newHeight: containerHeight,
    newWidth: containerWidth
  })

  const newWidth = containerSize.newWidth
  const newHeight = containerSize.newHeight

  useEffect(() => {
    if (props.fullScreen) {
      if (window.innerWidth > 900) {
        setContainerSize({
          newWidth: Math.min(1300, window.innerWidth) - 20,
          newHeight: 0.5 * window.innerWidth
        })
      } else if (window.innerWidth > 500) {
        setContainerSize({
          newWidth: window.innerWidth - 20,
          newHeight: 0.6 * window.innerHeight
        })
      } else {
        setContainerSize({
          newWidth: window.innerWidth - 20,
          newHeight: 0.4 * window.innerHeight
        })
      }
    } else {
      setContainerSize({
        newWidth: containerWidth,
        newHeight: containerHeight
      })
    }
  }, [containerWidth])

  useEffect(() => {
    if (props.videoSrc) {
      const videoId = getBrightcoveVideoId(props.videoSrc)
      if (videoId) {
        const video = document.querySelector(
          `video[data-video-id="${videoId}"]`
        )

        if (video) {
          const player = window.videojs.getPlayer(video)

          props.isHover ? player.play() : player.pause()
        }
      }
    }
  }, [props.isHover])

  if (props.videoSrc) {
    return (
      <VideoWrapper
        pointerEvents={props.disableVideoClick ? 'true' : ''}
        height={props.backgroundHeight || '100%'}
        width={props.backgroundWidth || '100%'}
        style={props.style}
        ref={ref}
      >
        {newWidth && newHeight && (
          <VideoBackground
            newWidth={newWidth}
            newHeight={newHeight}
            isHover={props.isHover}
            {...props}
          />
        )}
      </VideoWrapper>
    )
  } else if (props.src) {
    const image =
      props.imageUrl == null
        ? newWidth == null
          ? null
          : getImgixUrl({ ...props, newHeight, newWidth }).url
        : props.imageUrl

    return (
      <>
        {props.isArticle ? (
          <>
            <BackgroundContainer
              ref={ref}
              height={props.backgroundHeight}
              width={props.backgroundWidth || '100%'}
              fullScreen={props.fullScreen}
              noBorderRadius={props.noBorderRadius}
              templateNumber={props.templateNumber}
              style={props.style}
              id="background_container"
            >
              {image && ((newWidth && newHeight) || !props.lazyload) && (
                <ArticleImageWrapper
                  padding={0}
                  isBorderBox={props.isBorderBox}
                  style={props.articleImageWrapperStyle}
                >
                  <Picture
                    {...props}
                    newWidth={newWidth}
                    newHeight={newHeight}
                  />
                  {props.children}
                </ArticleImageWrapper>
              )}
            </BackgroundContainer>
          </>
        ) : (
          <BackgroundContainer
            ref={ref}
            height={props.backgroundHeight}
            width={props.backgroundWidth || '100%'}
            fullScreen={props.fullScreen}
            noBorderRadius={props.noBorderRadius}
            templateNumber={props.templateNumber}
            style={props.style}
            id="background_container"
          >
            {((newWidth && newHeight) || !props.lazyload) && (
              <ImageWrapper padding={0} isBorderBox={props.isBorderBox}>
                <img
                  alt={props.title}
                  width="100%"
                  loading={props.loading || 'lazy'}
                  src={image}
                  className={classnames(
                    gridStyles[props.fullScreen ? 'fullScreenImage' : 'image'],
                    gridStyles[
                      props.templateNumber === 3 ? 'imageTemplate3' : ''
                    ],
                    'lazyload'
                  )}
                  style={{
                    objectFit: props.imageObjectFit ? 'cover' : 'unset'
                  }}
                />
                {props.children}
              </ImageWrapper>
            )}
          </BackgroundContainer>
        )}
      </>
    )
  } else {
    return (
      <BackgroundContainer style={props.style} ref={ref}>
        {props.children}
      </BackgroundContainer>
    )
  }
}
export default memo(BackgroundCover)
