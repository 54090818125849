import React, { useState } from 'react'
import { globalHistory } from '@reach/router'
import Helmet from 'react-helmet'
import loadable from '@loadable/component'

import 'lazysizes'
import '../utils/unveilhook'
import styled from 'styled-components'
//ToolBar
import FingerPrintManager from '../utils/FingerPrintManager'

// New NAV
import Navigation from '../components/new-navigation/navigation'
import { device } from '../utils/screenSizes'

const ReduxToastr = loadable(() =>
  import(/* webpackPrefetch: true */ 'react-redux-toastr')
)
const BreakingNews = loadable(() =>
  import(/* webpackPrefetch: true */ '../components/breakingNews/BreakingNews')
)
const Notifications = loadable(() =>
  import('../components/notifications/notifications')
)
const PrivacyPopup = loadable(() =>
  import('../components/PrivacyPopup/PrivacyPopup')
)
const ToolbarBody = loadable(() =>
  import(/* webpackPrefetch: true */ './toolbar/toolbar.js')
)

loadable(() => import('react-loading-skeleton/dist/skeleton.css'))

FingerPrintManager.init()

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 157px;
  position: relative;
  padding-top: 10px;

  .photoArticle & {
    margin-top: 127px;
  }

  // 812px: iphone X
  // 896px: iphone 11/pro/XR
  // 926px: iphone 12 pro max
  @media ${device.s},
    (max-width: 812px) and (orientation: landscape),
    (max-width: 896px) and (orientation: landscape),
    (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    padding-top: ${({ isAsharqPopupshown }) =>
      isAsharqPopupshown ? '210px' : '120px'};
    margin-top: 0px;

    .photoArticle & {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 812px) and (orientation: portrait),
    (max-width: 896px) and (orientation: portrait),
    (min-width: 428px) and (max-width: 926px) and (orientation: portrait) {
    padding-top: ${({ isAsharqPopupshown }) =>
      isAsharqPopupshown ? '200px' : '110px'};
    margin-top: 0px;

    .photoArticle & {
      margin-top: 0;
    }
  }

  @media ${device.tabletPortrait} {
    margin-top: 60px;
  }

  @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
    padding-top: 10px;
    margin-top: 157px;
  }
`

if (typeof window !== 'undefined') {
  window.adsConfig = window.adsConfig || {}
  window.adsConfig.limit = 20
  window.adsConfig.rendered = window.adsConfig.rendered || 0
}

globalHistory.listen(({ action }) => {
  if (action === 'PUSH' && window.adsConfig) {
    window.adsConfig.rendered = 0
  }
})

const Layout = ({
  location,
  title,
  lang,
  children,
  isArticle = false,
  maxWidth = '1300px',
  padding = '0 10px 10px 10px',
  allowPopUp = false,
  articleId,
  layoutType = 'article',
  className = ''
}) => {
  const [isAsharqPopupshown, setIsAsharqPopupshown] = useState(false)

  // useEffect(() => {
  //   // there is a transition delay of 500 miliseconds
  //   // see gatsby-browser
  // }, [location.href])

  const isFullWidthLayout = layoutType === 'fullWidth'
  const maxLayoutWidth = isFullWidthLayout ? '1920px' : maxWidth
  const layoutPadding = isFullWidthLayout ? 'initial' : '0 10px 10px 10px'

  return (
    <>
      <ReduxToastr
        timeOut={3000}
        preventDuplicates
        position="top-right"
        showCloseButton={false}
      />
      <ToolbarBody />
      <div
        dir={lang ? (lang === 'ar' ? 'rtl' : 'ltr') : 'rtl'}
        lang={lang || 'ar'}
        className={className || layoutType}
        style={{
          padding: layoutPadding,
          maxWidth: maxLayoutWidth,
          margin: '0 auto',
          position: 'relative'
        }}
      >
        <Container className="anime" isAsharqPopupshown={isAsharqPopupshown}>
          <Navigation
            lang={lang}
            location={location}
            allowPopUp={allowPopUp}
            isArticle={isArticle}
            setIsAsharqPopupshown={setIsAsharqPopupshown}
            articleId={articleId}
          />

          <main
            className="pageContainer"
            style={{
              position: 'relative'
            }}
          >
            {children}
          </main>
          <Notifications />

          <PrivacyPopup />
          <BreakingNews />

          <Helmet>
            <link
              rel="preload"
              href="https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/css/toastr.min.css"
              as="style"
              onLoad="this.onload=null;this.rel='stylesheet'"
            />
            <noscript>
              {`
              <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/css/toastr.min.css"
              />
            `}
            </noscript>
            <script
              type="text/javascript"
              src="https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/js/toastr.min.js"
              async
              defer
            />

            <meta name="theme-color" content="#000000" />
            <meta
              property="fb:pages"
              content={`${process.env.GATSBY_FACEBOOK_PAGE_ID}`}
            />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, viewport-fit=cover"
            />
          </Helmet>
        </Container>
      </div>
    </>
  )
}

export default Layout
