import { debounce } from 'lodash'

import {
  sendEventVideoLooped,
  sendEventVideoMute,
  sendEventVideoPause,
  sendEventVideoPlay,
  sendEventVideoStop,
  sendEventVideoUnmute
} from '../../utils/sendEvent'

export const initVideoPlayer = (myPlayer, articleId, videoId, customPage) => {
  addForwardBackwardButton(myPlayer)
  addPlayPause(myPlayer, articleId, videoId, customPage)
}

const addPlayPause = (myPlayer, articleId, videoId, customPage) => {
  const playerDom = myPlayer.el_
  myPlayer.on(
    'pause',
    debounce(
      event => {
        const eventData = {
          itemId: videoId,
          time: myPlayer.currentTime(),
          sourceId: articleId,
          sourceType: 'article',
          sound: myPlayer.muted() ? 'mute' : 'unmute'
        }
        sendEventVideoPause(eventData, customPage)
      },
      1000,
      {
        leading: true,
        trailing: false
      }
    )
  )

  if (playerDom) {
    const volumeBtn = myPlayer.el_.querySelectorAll('.vjs-volume-panel')
    if (volumeBtn && volumeBtn.length > 0) {
      volumeBtn[0].addEventListener('click', () => {
        const eventData = {
          itemId: videoId,
          time: myPlayer.currentTime(),
          sourceId: articleId,
          sourceType: 'article'
        }

        if (!myPlayer.muted()) {
          sendEventVideoUnmute(eventData, customPage)
        } else {
          sendEventVideoMute(eventData, customPage)
        }
      })
    }
  }

  myPlayer.on(
    'ended',
    debounce(
      event => {
        const eventData = {
          itemId: event.target.dataset.videoId,
          time: myPlayer.currentTime(),
          sourceId: articleId,
          sourceType: 'article'
        }
        sendEventVideoStop(eventData, customPage)
      },
      1000,
      {
        leading: true,
        trailing: false
      }
    )
  )

  myPlayer.on(
    'play',
    debounce(
      event => {
        const eventData = {
          itemId: event.target.dataset.videoId,
          time: myPlayer.currentTime(),
          sourceId: articleId,
          sourceType: 'article',
          sound: myPlayer.muted() ? 'mute' : 'unmute'
        }
        sendEventVideoPlay(eventData, customPage)
      },
      1000,
      {
        leading: true,
        trailing: false
      }
    )
  )

  myPlayer.on('seeked', event => {
    if (myPlayer.currentTime() < 1) {
      const eventData = {
        itemId: event.target.dataset.videoId,
        sourceId: articleId,
        sourceType: 'article'
      }
      sendEventVideoLooped(eventData, customPage)
    }
  })
}
// this code is adjusted from 'https://player.support.brightcove.com/code-samples/brightcove-player-sample-back-and-forward-buttons.html'
const addForwardBackwardButton = myPlayer => {
  const playerDom = myPlayer.el_
  // +++ Create divs for buttons +++
  let jumpAmount = 10,
    controlBar,
    insertBeforeNode,
    newElementBB = document.createElement('div'),
    newElementFB = document.createElement('div'),
    newImageBB = document.createElement('img'),
    newImageFB = document.createElement('img')

  // +++ Assign IDs for later element manipulation +++
  newElementBB.id = 'backButton'
  newElementBB.classList.add('backButton')
  newElementFB.id = 'forwardButton'
  newElementFB.classList.add('forwardButton')

  // +++ Assign properties to elements and assign to parents +++
  newImageBB.setAttribute(
    'src',
    'https://player.support.brightcove.com/assets/images/code-samples/brightcove-player-sample-back-forward-buttons/back-button.png'
  )
  newImageBB.setAttribute('width', '25px')
  newElementBB.appendChild(newImageBB)
  newImageFB.setAttribute(
    'src',
    'https://player.support.brightcove.com/assets/images/code-samples/brightcove-player-sample-back-forward-buttons/forward-button.png'
  )

  newImageFB.setAttribute('width', '25px')
  newElementFB.appendChild(newImageFB)

  // +++ Get controlbar and insert elements +++
  if (playerDom) {
    const controlBarDom = playerDom.querySelectorAll('.vjs-control-bar')
    const insertBeforeNodeDom = playerDom.querySelectorAll('.vjs-volume-panel')
    if (controlBarDom && controlBarDom.length > 0) {
      controlBar = controlBarDom[0]
    }

    if (insertBeforeNodeDom && insertBeforeNodeDom.length > 0) {
      insertBeforeNode = insertBeforeNodeDom[0]
    }

    if (controlBar && insertBeforeNode) {
      // Get the element to insert buttons in front of in conrolbar

      // Insert the button div in proper location
      controlBar.insertBefore(newElementBB, insertBeforeNode)
      controlBar.insertBefore(newElementFB, insertBeforeNode)

      // +++ Add event handlers to jump back or forward +++
      // Back button logic, don't jump to negative times
      newElementBB.addEventListener('click', function() {
        var newTime,
          rewindAmt = jumpAmount,
          videoTime = myPlayer.currentTime()
        if (videoTime >= rewindAmt) {
          newTime = videoTime - rewindAmt
        } else {
          newTime = 0
        }
        myPlayer.currentTime(newTime)
      })

      // Forward button logic, don't jump past the duration
      newElementFB.addEventListener('click', function() {
        var newTime,
          forwardAmt = jumpAmount,
          videoTime = myPlayer.currentTime(),
          videoDuration = myPlayer.duration()
        if (videoTime + forwardAmt <= videoDuration) {
          newTime = videoTime + forwardAmt
        } else {
          newTime = videoDuration
        }
        myPlayer.currentTime(newTime)
      })
    }
  }
}
