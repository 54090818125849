import React from 'react'

import searchIcon from './assets/search-icon.svg'
import clearIcon from '../../search/assets/clear-icon.svg'
import closeIcon from './assets/closeSearch.svg'
import {
  SearchBarContainer,
  SearchText,
  SearchArea,
  SearchInput,
  SearchInputContainer,
  SearchTextContainer
} from './search.styled'

const SearchBar = props => {
  const {
    debouncedSearch,
    setSearchText,
    searchText,
    clearSearch,
    handleOnFocus,
    handleCloseIcon
  } = props
  return (
    <SearchBarContainer>
      <SearchArea>
        <SearchTextContainer>
          <img
            src={closeIcon}
            alt="close search results"
            loading="lazy"
            className="searchClearIcon"
            onClick={handleCloseIcon}
          />
          <SearchText>إبحث في بث الشرق</SearchText>
        </SearchTextContainer>
        <SearchInputContainer>
          <SearchInput
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter' && searchText) {
                debouncedSearch(searchText)
              }
            }}
            onFocus={handleOnFocus}
          />
          {searchText ? (
            <img
              onClick={() => clearSearch()}
              className="clearIcon"
              src={clearIcon}
              loading="lazy"
              alt="Clear Search"
            />
          ) : (
            <img
              className="search"
              src={searchIcon}
              alt="Search"
              loading="lazy"
            />
          )}
        </SearchInputContainer>
      </SearchArea>
    </SearchBarContainer>
  )
}

export default SearchBar
