import React, { useEffect, useState } from 'react'
import { isValid, differenceInDays } from 'date-fns'

import testMobile from '../../utils/testMobile'
import {
  PopupContainer,
  CloseIconContainer,
  TextContainer,
  LinkBtnContainer,
  Text,
  AppLink
} from './styles'

import CloseIcon from './assets/closeBtn.svg'
import AsharqAppLogo from './assets/asharqAppIcon.png'

const ARTICLE_PAGE_TEXT = 'اقرأ هذه المقالة في تطبيق الشرق للأخبار'
const HOME_PAGE_TEXT = 'تصفح تطبيق الشرق للأخبار'
const HOME_PAGE_LINK = 'https://alsharq.page.link/main'
const ARTICLE_PAGE_LINK =
  'https://alsharq.page.link/?ibi=com.alsharq.news&isi=1539149426&imv=1.0.0&apn=com.alsharq.app.alsharq_app&link='
const LOCAL_STORAGE_KEY = 'LAST_SHOWN_POPUP_DATE'

const AsharqAppPopup = ({
  allowPopUp,
  isArticle,
  setIsAsharqPopupshown,
  articleId,
  toggleAsharqAppPopup
}) => {
  const [showPopup, setShowPopup] = useState(false)
  const isMobile = testMobile()

  useEffect(() => {
    const lastShownPopupDate = localStorage.getItem(LOCAL_STORAGE_KEY)

    if (
      isMobile &&
      allowPopUp &&
      (!lastShownPopupDate ||
        (lastShownPopupDate &&
          isValid(new Date(lastShownPopupDate)) &&
          differenceInDays(new Date(), new Date(lastShownPopupDate)) > 5))
    ) {
      setShowPopup(true)
      setIsAsharqPopupshown(true)
      toggleAsharqAppPopup(true)
    } else {
      toggleAsharqAppPopup(false)
    }
  }, [])

  const handlePopupClick = () => {
    setShowPopup(false)
    setIsAsharqPopupshown(false)

    // store today's date in local storage
    localStorage.setItem(LOCAL_STORAGE_KEY, new Date())

    toggleAsharqAppPopup(false)
  }

  const getAppLink = () => {
    if (isArticle) {
      const articleJson = {
        screen: 'article',
        id: articleId
      }
      const base64EncodedString = Base64.encode(JSON.stringify(articleJson))
      const articleLink = encodeURIComponent(
        `https://alsharq.page.link/main?params=${base64EncodedString}`
      )
      return `${ARTICLE_PAGE_LINK}${articleLink}`
    }
    // Asharq app link
    return HOME_PAGE_LINK
  }

  if (showPopup && allowPopUp) {
    return (
      <PopupContainer className="popup-container">
        <CloseIconContainer onClick={handlePopupClick}>
          <img src={CloseIcon} alt="dismiss-popup" />
        </CloseIconContainer>
        <TextContainer>
          <img src={AsharqAppLogo} alt="dismiss-popup" />
          <Text>{isArticle ? ARTICLE_PAGE_TEXT : HOME_PAGE_TEXT}</Text>
        </TextContainer>
        <LinkBtnContainer>
          <AppLink
            href={getAppLink()}
            target="_blank"
            onClick={handlePopupClick}
          >
            {'افتح التطبيق'}
          </AppLink>
        </LinkBtnContainer>
      </PopupContainer>
    )
  } else {
    return null
  }
}

export default AsharqAppPopup
