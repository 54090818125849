import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { Form, Field } from 'form'

import Modal from '../../modal'
import CheckboxGroup from '../../checkboxGroup'
import Checkbox from '../../checkbox'
import FormField from '../formField'
import fields from './fields'
import schema from './schema'
import closeIcon from '../close.svg'

import {
  Container,
  FormButton,
  SectionTitle,
  Header,
  Title,
  Subtitle,
  Close,
  FormContainer,
  FieldsContainer,
  RegisterFooter
} from '../forms.styled'

const RegistrationForm = ({ isVisible, register, close, categories }) => {
  const [formApi, setFormApi] = useState()
  const [_, setAgreed] = useState(false)
  const [footerHeight, setFooterHeight] = useState(0)
  const [headerHeight, setHeaderHeight] = useState(0)
  const footer = useRef()
  const header = useRef()
  categories = categories
    .filter(category => category.showInMenu)
    .map(cat => ({ label: cat.ar, value: cat.id }))

  useEffect(() => {
    calculateSpacing()
  })

  useEffect(() => {
    document.body.classList[isVisible ? 'add' : 'remove']('prevent-scroll')
  }, [isVisible])

  const calculateSpacing = () => {
    if (footer.current) {
      setFooterHeight(footer.current.scrollHeight)
    }

    if (header.current) {
      setHeaderHeight(header.current.scrollHeight)
    }
  }

  const handleSubmit = () => {
    if (formApi.validate()) {
      const {
        // country,
        // maritalStatus,
        // noChildren,
        // monthlyIncome,
        // languages,
        // occupation,
        ...values
      } = formApi.values()

      const payload = {
        ...values,
        fingerprint: localStorage.getItem('fingerprint'),
        language: 'ar'
      }

      register(payload)
    } else {
      setTimeout(() => {
        calculateSpacing()
      }, 500)
    }
  }

  if (!isVisible) {
    return null
  }

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={close}
      contentLabel="Register"
      ariaHideApp={false}
    >
      <Container dir="rtl">
        <Header ref={header}>
          <Close onClick={close}>
            <img
              src={closeIcon}
              alt="X"
              height="20px"
              width="20px"
              loading="lazy"
            />
          </Close>
          <Title>تسجيل حساب</Title>
          <Subtitle>
            شجع المستخدم على التسجيل. المحتوى المطلوب من العميل.
          </Subtitle>
        </Header>

        <Form
          formApi={setFormApi}
          schema={schema}
          initialValues={{ agreed: false }}
        >
          <FormContainer
            footerHeight={footerHeight}
            headerHeight={headerHeight}
          >
            <SectionTitle>تفاصيل</SectionTitle>
            <FieldsContainer>{fields.basic.map(FormField)}</FieldsContainer>

            <SectionTitle>معلومات أخرى</SectionTitle>
            <FieldsContainer>
              {fields.moreInfo.map(FormField)}

              <Field
                required
                name="loveCategories"
                label="موضوعات الاهتمام"
                items={categories}
                render={CheckboxGroup}
              />
            </FieldsContainer>

            {/* <SectionTitle>تفاصيل أخرى "اختيارية"</SectionTitle>
            <FieldsContainer>{fields.optional.map(FormField)}</FieldsContainer> */}
          </FormContainer>

          <RegisterFooter ref={footer}>
            <Field
              name="agreed"
              label="أوافق على الشروط والأحكام"
              render={Checkbox}
              onChange={setAgreed}
              style={{ width: 240 }}
            />

            <FormButton onClick={handleSubmit} type="submit">
              تسجيل
            </FormButton>
          </RegisterFooter>
        </Form>
      </Container>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    register: payload => {
      dispatch({ type: 'REGISTER', payload })
    },
    close: () => {
      dispatch({ type: 'HIDE_REGISTER' })
    }
  }
}

function mapStateToProps(state) {
  const { categories, register } = state
  const { isVisible } = register

  return { isVisible, categories: categories.data }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm)
