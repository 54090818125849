import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const LatestNewsDesktopContainer = styled.div`
  border: 1px solid #ececec;
  border-radius: 0 10px 0 10px;
  height: 500px;
  background-color: #fff;
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  margin-bottom: 30px;

  @media ${device.s} {
    margin-bottom: 50px;
    display: block;
    padding-top: 20px;
  }

  .searchContainer {
    grid-column: 1;
    grid-row: 1;
  }

  @media screen and (orientation: landscape) and (min-width: 319px) and (max-width: 900px) {
    grid-template-columns: auto;
    margin-bottom: 60px;
  }
`

export const VideoWrapper = styled.div`
  position: relative;
  background: #494949;
  border-radius: 0 0 0 15px;
  height: 625px;

  @media ${device.s} {
    width: 100%;
    height: 200px;
    border-radius: unset;

    .live-tv-player {
      border-radius: unset !important;
    }
  }
`
export const VideoInner = styled.div`
  width: 100%;
  height: 100%;

  .live-tv-page-video-player video-js {
    border-radius: 0;
  }

  video {
    @media ${device.s} {
      width: 100% !important;
      height: 100% !important;
    }
  }
`
export const LiveTvMobileView = styled.div`
  width: 100%;
  height: 200px;

  @media ${device.m} {
    width: 100%;
    height: 150px;
  }
`
export const CustomVideoPlayerWrapper = styled.div`
  position: relative;
  background: rgb(51, 51, 51);
  border-radius: 0 0 0 15px;
  height: 625px;

  @media ${device.s} {
    width: 100%;
    height: 200px;
    border-radius: unset;
  }

  .player-footer {
    @media ${device.s} {
      height: 50px !important;
    }
  }
`
export const CustomVideoPlayerInner = styled.div`
  width: 100%;
  height: 90%;
  position: relative;

  @media (max-width: 500px) {
    height: 150px;
  }

  video-js {
    border-radius: 0 15px 0 0;
    width: 100%;
    height: 100%;
  }
`
