import countries from "../../../constants/countries"

export default {
  basic: [
    { name: "firstName", label: "الاسم الأول", required: true },
    { name: "lastName", label: "أسم العائلة", required: true },
    { name: "email", label: "البريد الإلكتروني", required: true },
    { name: "password", label: "كلمة السر", required: true, type: "password" },
  ],
  moreInfo: [
    {
      name: "gender",
      label: "الجنس",
      required: true,
      component: "radio",
      items: [
        { value: "male", label: "ذكر" },
        { value: "female", label: "أنثى" },
      ],
    },
    {
      name: "ageRange",
      label: "العمر",
      component: "select",
      items: [
        { label: "10-20", value: "10-20" },
        { label: "20-30", value: "20-30" },
        { label: "30-40", value: "30-40" },
        { label: "40-50", value: "40-50" },
        { label: "50-60", value: "50-60" },
        { label: "60-70", value: "60-70" },
        { label: "70-80", value: "70-80" },
        { label: "80-90", value: "80-90" },
        { label: "90-100", value: "90-100" },
      ],
      required: true,
    },
  ],
  optional: [
    {
      name: "country",
      label: "الجنسية",
      items: countries,
      component: "select",
    },
    {
      name: "maritalStatus",
      label: "الحالة الاجتماعية",
      required: true,
      component: "radio",
      items: [
        { value: "married", label: "متزوج" },
        { value: "single", label: "أعزب" },
        { value: "divorced", label: "مطلق" },
      ],
    },
    {
      name: "noChildren",
      label: "عدد الأطفال",
      component: "select",
      items: [
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4+", label: "4+" },
      ],
    },
    {
      name: "monthlyIncome",
      label: "الدخل الشهري التقريبي",
      component: "select",
      items: [
        { value: "100-1000", label: "100-1000" },
        { value: "$1000-$2000", label: "$1000-$2000" },
        { value: "$2000-$5000", label: "$2000-$5000" },
        { value: "more than $5000", label: "more than $5000" },
      ],
    },
    {
      name: "occupation",
      label: "الوظيفة م مجال العمل",
      component: "select",
      items: [
        { value: "Bus Driver", label: "Bus Driver" },
        { value: "Bus Mechanic", label: "Bus Mechanic" },
        { value: "Bus Valeter", label: "Bus Valeter" },
        { value: "Business Consultant", label: "Business Consultant" },
        { value: "Business Proprietor", label: "Business Proprietor" },
        { value: "Butcher", label: "Butcher" },
        { value: "Butchery Manager", label: "Butchery Manager" },
        { value: "Butler", label: "Butler" },
        { value: "Buyer", label: "Buyer" },
        { value: "Cab Driver", label: "Cab Driver" },
        { value: "Cabinet Maker", label: "Cabinet Maker" },
        { value: "Cable Contractor", label: "Cable Contractor" },
        { value: "Cable Jointer", label: "Cable Jointer" },
        { value: "Cable TV Installer", label: "Cable TV Installer" },
        { value: "Cafe Owner", label: "Cafe Owner" },
        { value: "Cafe Staff", label: "Cafe Staff" },
        { value: "Cafe Worker", label: "Cafe Worker" },
        { value: "Calibration Manager", label: "Calibration Manager" },
        { value: "Camera Repairer", label: "Camera Repairer" },
        { value: "Cameraman", label: "Cameraman" },
        { value: "Car Dealer", label: "Car Dealer" },
        { value: "Car Delivery Driver", label: "Car Delivery Driver" },
        { value: "Car Park Attendant", label: "Car Park Attendant" },
        { value: "Car Salesman", label: "Car Salesman" },
        { value: "Car Valet", label: "Car Valet" },
        { value: "Car Wash Attendant", label: "Car Wash Attendant" },
        { value: "Care Assistant", label: "Care Assistant" },
        { value: "Care Manager", label: "Care Manager" },
      ],
    },
    {
      name: "languages",
      label: "اللغات",
      component: "select",
      items: [
        { label: "English", value: "English" },
        { label: "Arabic ", value: "Arabic " },
        { label: "French", value: "French" },
        { label: "Other", value: "other" },
      ],
    },
  ],
}
