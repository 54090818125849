import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import SearchBar from './searchBar'
import SearchResults from './searchResults'
import testMobile from '../../../utils/testMobile'

import { SearchContainer } from './search.styled'

const LiveTvSearch = props => {
  const {
    fetchSearch,
    resetSearch,
    searchData,
    handleReplay,
    handleClearReplayVideo,
    searchTerm
  } = props
  const [searchText, setSearchText] = useState(searchTerm || '')
  const [extraClass, setExtraClass] = useState('')
  const [showDefaultState, setShowDefaultState] = useState(
    testMobile() ? false : true
  )
  const { fetching, error, data } = searchData

  useEffect(() => {
    if (searchText) {
      fetchData(searchText)
    }
    return () => {
      resetSearch()
    }
  }, [])

  const fetchData = searchTerm => {
    if (searchTerm && searchTerm != '') {
      resetSearch()
      const payload = {
        q: searchTerm,
        limit: 100
      }
      fetchSearch(payload)
    }
  }

  const clearSearch = () => {
    setSearchText('')
    resetSearch()
    handleClearReplayVideo()
  }

  const debouncedSearch = debounce(term => fetchData(term), 500, {
    leading: false,
    trailing: true
  })

  const handleOnFocus = () => {
    if (!testMobile()) {
      return
    }

    setExtraClass('modal-view-search-container')
    setShowDefaultState(true)
  }

  const handleCloseIcon = () => {
    setExtraClass('')
    setShowDefaultState(false)
  }

  const replayVideoHandler = (replayVideo, searchTerm) => {
    if (testMobile()) {
      setExtraClass('')
      setShowDefaultState(false)
    }

    handleReplay(replayVideo, searchTerm)
  }

  return (
    <SearchContainer className={`searchContainer ${extraClass}`}>
      <SearchBar
        debouncedSearch={debouncedSearch}
        searchText={searchText}
        setSearchText={setSearchText}
        clearSearch={clearSearch}
        handleOnFocus={handleOnFocus}
        handleCloseIcon={handleCloseIcon}
      />
      <SearchResults
        showDefaultState={showDefaultState}
        results={data}
        isLoading={fetching}
        searchText={searchText}
        handleReplay={replayVideoHandler}
        isError={error}
      />
    </SearchContainer>
  )
}

const mapStateToProps = ({ liveTvSearch }) => {
  return { searchData: liveTvSearch }
}

const mapDispatchToProps = dispatch => {
  return {
    resetSearch: () => {
      dispatch({ type: 'LIVETV_SEARCH_RESET' })
    },

    fetchSearch: payload => {
      dispatch({ type: 'LIVETV_SEARCH_REQUESTED', payload })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveTvSearch)
