import { useEffect } from "react"

export const useOnClickOutside = (refArray, handler) => {
  useEffect(() => {
    const listener = event => {
      if (
        refArray.find(
          ref => ref && ref.current && ref.current.contains(event.target)
        )
      ) {
        return
      }

      handler(event)
    }

    document.addEventListener("mousedown", listener)
    document.addEventListener("touchstart", listener)

    return () => {
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
    }
  }, [handler, refArray])
}
