import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const NavigationContainer = styled.nav`
  background: white;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  color: #7d7d82;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  transition: transform 200ms linear;
`

// Upper
export const NavigationUpper = styled.div`
  display: flex;
  height: 78px;
  border-bottom: 1px solid rgb(236, 236, 236);
  align-items: center;
  justify-content: space-between;
`

export const NavigationControls = styled.div`
  padding: 0 20px;
  display: flex;
  flex: 1;
  align-items: center;
  width: 220px;
  @media ${device.s} {
    padding: 0 10px;
    min-width: 110px;
  }

  @media (max-width: 360px) {
    min-width: 90px;
  }
`

export const NavigationWeather = styled.div`
  padding: 0 20px;
  font-size: 0.9em;
  text-align: left;
  width: 220px;
  flex: 1;

  @media ${device.s} {
    padding: 0 10px;
    font-size: 0.8em;
    min-width: 110px;
  }

  @media (max-width: 360px) {
    font-size: 0.75em;
    min-width: 90px;
  }
`

export const NavigationMenuIcon = styled.div`
  // background: green;
`

export const NavigationSearchIcon = styled.div`
  margin-right: 64px;

  @media ${device.s} {
    margin-right: 42px;
    display: none;
  }
`

export const NavigationLogoImg = styled.img`
  @media ${device.s} {
    // margin-left: -60px;
    height: 50px;
    width: 90px;
  }

  @media (max-width: 360px) {
    height: 45px;
    width: 80px;
  }
`

export const NavigationLogo = styled.div`
  text-align: center;
  flex-grow: 1;
  cursor: pointer;

  a {
    text-decoration: none;
    display: flex;
    justify-content: center;
  }

  @media ${device.s} {
    flex-grow: unset;
  }
`

// Lower

export const NavigationLower = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  border-bottom: 1px solid #d8d8d8;
`

export const NavigationCategories = styled.div`
  flex-grow: 1;
  padding: 0 10px;

  // @media ${device.s} {
  //   display: none;
  // }

  @media ${device.s} {
    flex-grow: 1;
    width: 85%;
    overflow: hidden;
    overflow-x: scroll;

    a {
      padding: 0 10px;
    }
  }

  @media ${device.m} {
    flex-grow: 1;
    width: 85%;

    a {
      padding: 0 10px;
    }
  }

  a {
    color: #7d7d82;
    font-size: 0.9em;
    padding: 0 15px;
    text-decoration: none;

    &:hover {
      color: #424242;
    }
  }
`

export const NavigationCategoriesContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
`

export const NavigationUser = styled.div`
  padding: 0 20px;
`

export const NavigationSearchBar = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  border-bottom: 1px solid #d8d8d8;
`

export const NavigationSearch = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  padding: 0 20px;
`
