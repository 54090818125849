import { breakpoints } from '../constants/app'

export const device = {
  s: `(max-width: ${breakpoints.m}px)`,
  m: `(min-width: ${breakpoints.m}px) and (max-width: ${breakpoints.l}px)`,
  l: `(min-width: ${breakpoints.l}px) and (max-width: ${breakpoints.xl}px)`,
  xl: `(min-width: ${breakpoints.xl}px)`,
  tabletPortrait:
    'only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1)',
  isTabletPortrait: () => {
    if (typeof window !== 'undefined') {
      return window.matchMedia(
        'only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1)'
      ).matches
    }

    return false
  },
  isTabletLandScape: () => {
    if (typeof window !== 'undefined') {
      return window.matchMedia(
        'only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1)'
      ).matches
    }
    return false
  },
  isTabletPro: () => {
    if (typeof window !== 'undefined') {
      return window.matchMedia(
        'only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)'
      ).matches
    }

    return false
  },
  isSmallDeviceLandscape: `only screen and (min-width: 414px) and (max-width: 896px) and (orientation: landscape), 
  only screen and (max-width: 767px) and (orientation: landscape), 
  screen and (max-width: 896px) and (orientation: landscape), 
  screen and (max-width: 812px) and (orientation: landscape), 
  screen and (max-width: 926px) and (orientation: landscape)`
}
