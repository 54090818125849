import React, { memo } from 'react'
import ReactPlayerLoader from '@brightcove/react-player-loader'

import { VideoInner, PlayIcon } from './background.styled'
import { initVideoPlayer } from '../videoplayer/initVideoPlayer'
import playIcon from './play-icon.png'
import { getBrightcoveVideoId } from '../../utils/common'

const PLAYER_IDS = {
  ASHARQ_PLAYER: 'lVwgchr3M',
  ASHARQ_PIP_PLAYER: 'yY75y58Jx'
}

const ACCOUNT_ID = 6120310431001

const VideoBackground = ({
  newWidth,
  newHeight,
  isHover,
  playDMVideoInView = false,
  shouldTriggerEventsOnAutoPlay = true,
  ...props
}) => {
  const videoId = getBrightcoveVideoId(props.videoSrc)

  let videoStyle
  if (props.disableControls && props.templateNumber === 3) {
    videoStyle = 'videoPlayerNoControls template3'
  } else if (props.disableControls && props.templateNumber === 6) {
    videoStyle = 'videoPlayerNoControls template6'
  } else if (props.disableControls) {
    videoStyle = 'videoPlayerNoControls'
  } else {
    videoStyle = 'videoPlayer vjs-pip-container'
  }

  if (!videoId) {
    return null
  }

  return (
    <>
      <VideoInner
        templateNumber={props.templateNumber}
        isArticle={props.isArticle}
      >
        {newWidth && newHeight && (
          <ReactPlayerLoader
            onSuccess={(el) => {
              const customPage = props.isLiveArticle ? 'livearticlepage' : null
              initVideoPlayer(el.ref, props.articleId, videoId, customPage)
              if (props.setLoadedVideo) {
                props.setLoadedVideo(true)
              }
            }}
            attrs={{ className: videoStyle }}
            accountId={ACCOUNT_ID}
            videoId={videoId}
            playerId={
              props.pip
                ? PLAYER_IDS.ASHARQ_PIP_PLAYER
                : PLAYER_IDS.ASHARQ_PLAYER
            }
            embedOptions={{
              responsive: true,
              controls: true,
              pip: props.pip ? true : false
            }}
            options={{
              loop: props.loopVideo || false,
              autoplay: props.autoplay,
              playsinline: true,
              controls: true
            }}
          />
        )}
        {props.children}
      </VideoInner>

      {props.showPlayIcon && <PlayIcon src={playIcon} />}
    </>
  )
}

export default memo(VideoBackground)
