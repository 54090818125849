import React from 'react'
import isEmpty from 'lodash/isEmpty'
import Highlighter from 'react-highlight-words'
import DateTimeService from '../../../utils/common'
import {
  SearchResultsContainer,
  SearchResultsDefaultState,
  DefaultStateText,
  SearchResultItem,
  ResultDate,
  ResultTitle,
  ResultBody,
  CCImage,
  PlayVideoButton,
  ReplayImageContainer,
  ReplayImageWrapper
} from './search.styled'
import { GridLoader, Spinner } from '../../grid/grid.styled'

import emptyResultsIcon from './assets/empty-results.svg'
import ccIcon from './assets/cc.svg'
import replayIcon from './assets/replay-icon.svg'

import { getCcText } from '../../../utils/liveTvSearchHelper'
import { GridError } from '../../grid/grid.styled'

const SearchResults = (props) => {
  const {
    showDefaultState,
    results = [],
    isLoading,
    handleReplay,
    searchText,
    isError = false
  } = props

  if (isError) {
    return (
      <GridError
        style={{
          color: '#FFF'
        }}
      >
        Unexpected Error
      </GridError>
    )
  }

  if (!showDefaultState && !isLoading && !results) {
    return null
  }

  return (
    <SearchResultsContainer className="search-results-container">
      {isLoading && (
        <GridLoader>
          <Spinner />
        </GridLoader>
      )}
      {!isLoading && showDefaultState && !results && (
        <SearchResultsDefaultState>
          <div
            style={{
              width: '100%'
            }}
          >
            <img
              className="search"
              src={emptyResultsIcon}
              alt="Empty Results"
              loading="lazy"
            />
          </div>
          <DefaultStateText>
            ابحث في البث المباشر للعودة ومشاهدته
          </DefaultStateText>
        </SearchResultsDefaultState>
      )}
      {!isLoading && results && !results.length && (
        <GridError
          style={{
            color: '#FFF'
          }}
        >
          لايوجد معروضات
        </GridError>
      )}
      {!isLoading &&
        !isEmpty(results) &&
        results.map((result, index) => (
          <SearchResultItem
            key={index}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleReplay(result, searchText)
            }}
          >
            <ResultDate>
              <time
                dateTime={DateTimeService.formattedDateTime(result.start_dt)}
              >
                {DateTimeService.formatInLocalTime(
                  result.start_dt,
                  'eeee dd MMMM yyyy hh:mm'
                )}
              </time>
            </ResultDate>
            <ResultTitle>{result.title}</ResultTitle>
            <ResultBody>
              <CCImage src={ccIcon} alt="CC" loading="lazy" />
              <Highlighter
                highlightClassName="hightlight-color"
                className="CCText"
                searchWords={[searchText]}
                highlightStyle={{ fontWeight: 'bold' }}
                textToHighlight={getCcText(result, searchText)}
              />
            </ResultBody>
            <PlayVideoButton onClick={() => handleReplay(result, searchText)}>
              <span>شغل الفيديو</span>
              <ReplayImageWrapper>
                <ReplayImageContainer>
                  <img src={replayIcon} alt="replay" loading="lazy" />
                </ReplayImageContainer>
              </ReplayImageWrapper>
            </PlayVideoButton>
          </SearchResultItem>
        ))}
    </SearchResultsContainer>
  )
}

export default SearchResults
