import Fingerprint2 from 'fingerprintjs2'
import { sendEvent, pageName, itemClickData } from '../utils/sendEvent'

import VisitManager from './VisitManager'

const ssrMode = typeof window === 'undefined'
class FingerPrintManager {
  init() {
    // this is done ones per browser session
    // so we use this fact to call itemClick

    if (!ssrMode) {
      if (itemClickData() !== null) {
        sendEvent('itemClick', {
          page: 'newpage',
          ...itemClickData()
        })
      }
      this.fingerprint = sessionStorage.getItem('fingerprint')

      if (this.fingerprint == null) {
        if (window.requestIdleCallback) {
          requestIdleCallback(() => {
            this.fingerprintReport()
          })
        } else {
          setTimeout(() => {
            this.fingerprintReport()
          }, 500)
        }
      } else {
        setTimeout(() => {
          this.fingerprintReport()
        }, 500)
      }
    }
  }

  fingerprintReport() {
    if (!ssrMode) {
      Fingerprint2.getPromise({}).then(function (components) {
        let fingerprintId = Fingerprint2.x64hash128(
          components
            .map(function (pair) {
              return pair.value
            })
            .join(),
          31
        )

        VisitManager.initWithData(
          {
            deviceFingerprint: fingerprintId,
            page: pageName(),
            deviceType: window.navigator.userAgent,
            metaData: {
              data: components
            }
          },
          sendEvent
        )

        sessionStorage.setItem('fingerprint', fingerprintId)
      })
    }
  }
}

const fingerPrintManager = new FingerPrintManager()

export default fingerPrintManager
