import React from "react"

import {
  Error,
  Label,
  ItemsContainer,
  LabelContainer,
  OptLabel,
  RadioOpt,
  Item,
  ActiveRadio,
  StatusLabel,
} from "./styles"

const Radio = ({ value, onChange, label, error, items = [], required }) => (
  <div>
    <LabelContainer>
      <Label>{label}</Label>
      {required && <StatusLabel>مطلوب</StatusLabel>}
    </LabelContainer>

    <ItemsContainer>
      {items.map(item => (
        <Item
          key={item.value}
          onClick={() =>
            onChange(value === item.value ? undefined : item.value)
          }
        >
          <RadioOpt>{value === item.value && <ActiveRadio />}</RadioOpt>
          <OptLabel>{item.label}</OptLabel>
        </Item>
      ))}
    </ItemsContainer>

    {error && <Error>{error}</Error>}
  </div>
)

export default Radio
