import React from 'react'

import { Link } from 'gatsby'

import {
  FontContainer,
  RingContainer,
  SocialShareContainer,
  CloseContainer
} from './livetvplayer.styled.js'
import LiveIcon from '../liveIcon'
import SocialControls from '../socialcontrols/socialcontrols'

import arrow from './arrow.svg'
import arrowUp from './arrowUp.svg'
import './index.scss'

const LiveTvPlayerFooter = props => {
  const {
    showSocialShareIcons = false,
    mobileView = false,
    onMobileViewClose = () => {},
    page
  } = props
  return (
    <div
      className={
        page && page === 'livetv'
          ? 'live-tv-page-player-footer'
          : 'player-footer'
      }
    >
      <RingContainer page={page}>
        <LiveIcon />
      </RingContainer>
      {mobileView || (page && page === 'livetv') ? (
        <FontContainer page={page}>
          <div style={{ paddingRight: '15px' }}>
            <span>تلفزيون الشرق</span>
          </div>
        </FontContainer>
      ) : (
        <FontContainer page={page}>
          <Link to={`/live`}>
            <span>تلفزيون الشرق</span>
            <img
              src={arrow}
              alt="arrow"
              style={{ marginRight: '10px', verticalAlign: 'middle' }}
            />
          </Link>
        </FontContainer>
      )}
      {showSocialShareIcons && (
        <SocialShareContainer>
          <SocialControls
            itemType={'video'}
            theme="light"
            visible={true}
            page={'livetv'}
            shareUri={'live'}
            article={{}}
            showBookmark={false}
            showLike={false}
          />
        </SocialShareContainer>
      )}
      {mobileView && (
        <CloseContainer onClick={onMobileViewClose}>
          <img src={arrowUp} alt="arrow-up" loading="lazy" />
        </CloseContainer>
      )}
    </div>
  )
}

export default LiveTvPlayerFooter
