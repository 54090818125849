import styled from "styled-components"

export const Label = styled.div`
  font-size: 1em;
`

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: #818181;
  margin-bottom: 14px;
`

export const Error = styled.div`
  color: #e03c20;
  font-family: "Lucida Grande";
  font-size: 0.8em;
  margin-top: 8px;
  text-align: end;
`

export const ItemsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 30px;
`

export const OptLabel = styled.div`
  color: #818181;
  font-size: 1em;
  margin-right: 10px;
`

export const RadioOpt = styled.div`
  height: 20px;
  width: 20px;
  background-color: #d8d8d8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ActiveRadio = styled.div`
  height: 12px;
  width: 12px;
  background-color: #5c8df0;
  border-radius: 50%;
`

export const Item = styled.div`
  display: flex;
  cursor: pointer;
`

export const StatusLabel = styled.div`
  font-size: 0.85em;
`
