import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const MenuContent = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  right: ${({ isOpen }) => (isOpen ? `0px` : `-100%`)};
  width: 320px;
  height: 100vh;
  background: #263238;
  color: #333;
  transition: ${({ animationTime }) => `right ${animationTime}s ease-out`};
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 999;

  a,
  .link {
    color: #fff;
    border-top: 1px solid #44555d;

    &:hover {
      text-decoration: underline;
    }
  }

  @media ${device.s} {
    width: 100vw;
    border-radius: 0;
    overflow-y: scroll;
  }
`
export const MobileLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`
export const MobileLogo = styled.img`
  @media ${device.s} {
    display: flex;
    width: 90px;
    height: 50px;
  }

  @media (max-width: 360px) {
    height: 45px;
    width: 80px;
  }
  display: none;
`

export const MobileSNSsContainer = styled.div`
  display: none;
  @media ${device.s} {
    min-height: 71px;
    display: flex;
  }
`
export const MobilePlatformText = styled.div`
  @media ${device.s} {
    display: flex;
    font-family: 'Neue Haas Grotesk Bloomberg';
    font-size: 1em;
    padding-top: 31px;
    padding-right: 34px;
    line-weight: 26px;
    color: white;
  }
  display: none;
`
export const MobileSNSIcons = styled.div`
  @media ${device.s} {
    display: flex;
    padding-right: 12px;

    a {
      border-top: none;
      display: flex;
      align-items: flex-end;
      padding: 6px 12px;
      padding-bottom: 22px;
    }
  }
  display: none;
`

export const SideMenuTitle = styled.div`
  @media ${device.s} {
    display: none;
  }
  display: flex;
  justify-content: flex-end;
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-size: 1em;
  padding-top: 22px;
  padding-left: 24px;
  line-weight: 26px;
  color: white;
`
export const LineContainer = styled.div`
  @media ${device.s} {
    display: none;
  }

  display: flex;
  justify-content: flex-end;
`
export const SideMenuTitleLine = styled.div`
  width: 31px;
  padding-top: 3px;
  // background-color: #c41b1a;
  border-radius: 1.5px;
  margin: 6px 12px 2px 24px;
  align-self: flex-end;
`
export const SNSIcons = styled.div`
  @media ${device.s} {
    display: none;
  }

  display: flex;
  justify-content: flex-end;
  padding-left: 18px;
  margin-bottom: 27px;

  a {
    border-top: none;
    display: flex;
    align-items: flex-end;
    padding: 6px;
  }
`
export const SNSIcon = styled.img`
  @media ${device.s} {
    height: 18px;
  }
  :hover {
    cursor: pointer;
  }
`

export const MenuSearch = styled.div`
  @media ${device.s} {
    padding-top: 10px;
  }

  position: sticky;
  top: 0;
  background-color: #263238;
  z-index: 1;
`

export const MenuItems = styled.div`
  margin-top: 33px;
  font-size: 1em;
  color: #424242;
  border-top: 1px solid #44555d;

  a,
  .link {
    border-top: unset;
    text-decoration: none;
    font-size: 1em;
    flex: 1;
  }
`
export const MenuItem = styled.div`
  display: flex;
  border-bottom: ${props =>
    props.hideBottomBorder ? 'none' : '1px solid #44555d'};

  &:hover {
    background: #2e3c44;
    cursor: pointer;
  }

  a,
  .link {
    padding-top: 15.5px;
    text-decoration: none;
    font-family: 'Bloomberg Arabic Beta v4';
    padding-right: calc(34px + 22px + 16px);
    padding-bottom: 16.5px;
  }
`
export const MenuIcons = styled.img`
  pointer-events: none;
  position: absolute;
  margin-top: 17.5px;
  margin-right: 34px;
  margin-bottom: 16.5px;
  height: 18px;
  width: 16px;
  margin-left: 22px;
`
export const CategoryTitle = styled.div`
  color: white;
  padding-top: 15px;
  padding-right: 34px;
  padding-bottom: 18px;
  font-family: Bloomberg Arabic Beta v4;
  font-size: 20px;
  font-weight: bold;
`

export const MenuCategories = styled.div`
  font-size: 0.9em;

  /* width */
  ::-webkit-scrollbar {
    width: 14px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #1f2a30;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fff;
  }

  li {
    list-style: none;
  }
`
export const MenuCategory = styled.div`
  font-size: 1em;
  min-height: 50px;

  a {
    display: block;
    padding: 15px 10px;
    text-decoration: none;
    padding-right: 34px;
    border-bottom: ${props =>
      props.showBottomLine ? '1px solid #44555d' : 'unset'};

    &:hover {
      background: #2e3c44;
      text-decoration: none;
    }
  }
`

export const MenuBackground = styled.div`
  display: ${({ startBackgroundTransition }) =>
    startBackgroundTransition ? 'block' : 'none'};
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0')};
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: ${({ animationTime }) => `opacity ${animationTime}s ease-out`};
`
