import { breakpoints, checkLandscape } from '../../constants/app'
import { useState, useLayoutEffect } from 'react'

export const gridSpanHelper = (
  index,
  sampleSize = 8,
  size = 'l',
  rowLength = 4,
  rowIndex = 0,
  rowHasLatestNews = false,
  isLatestNews = false,
  rowHasVote = false,
  isVote = false
) => {
  // exclude the inclusion of the latest new card from the count
  if (rowHasLatestNews) {
    rowLength = rowLength - 1
  }

  if (isLatestNews) {
    // needs work for all variations...
    let gridRow = 'span 2'
    let gridColumn = 'span 2'

    if (!rowHasVote) {
      if (rowLength === 1) {
        gridRow = 'span 3'
      } else if (rowLength === 2) {
        gridRow = 'span 2'
        gridColumn = 'span 2'
      } else if (rowLength === 3) {
        gridColumn = 'span 3'
      } else if (rowLength === 4) {
        gridRow = '1 / 3'
        gridColumn = '11 / 14'
      }
    }

    if (size === 'l' || size === 'xl') {
      return {
        gridRow,
        gridColumn
      }
    } else if (size === 'm') {
      return {
        gridColumn: rowLength === 1 ? '10 / 14' : '9 / 14',
        gridRow: rowLength === 1 ? '1 / 4' : '1 / 5'
      }
    } else {
      return {
        gridRow: '1 / 4',
        gridColumn: '10 / 14'
      }
    }
  } else if (isVote) {
    let gridRow = 'span 2'
    let gridColumn = 'span 10'

    if (size === 'm') {
      gridRow = 'span 3'
      gridColumn = 'span 9'
    }

    if (size === 's') {
      gridRow = 'span 9'
      gridColumn = 'span 12'
    }

    return { gridRow, gridColumn }
  } else if (rowLength === 4) {
    if (size === 'l' || size === 'xl') {
      if (rowIndex % 2 === 1) {
        switch (index % sampleSize) {
          case 0:
            return {
              gridRow: '1 / 2',
              gridColumn: 'span 3',
              textOutside: false,
              cardType: 1,
              cardSize: '1/4'
            }
          case 1:
            return {
              gridRow: '2 / 3 ',
              gridColumn: 'span 3',
              textOutside: false,
              cardType: 1,
              cardSize: '1/4'
            }
          case 3:
            return {
              gridRow: 'span 2',
              gridColumn: rowHasLatestNews ? 'span 4' : 'span 5',
              textOutside: false,
              cardType: 1,
              cardSize: '1/2'
            }
          case 2:
            return {
              gridRow: 'span 2',
              gridColumn: rowHasLatestNews ? 'span 3' : 'span 4',
              textOutside: false,
              cardType: 2,
              cardSize: '1/3'
            }
          default:
            return {
              gridRow: 'span 1',
              gridColumn: 'span 1',
              textOutside: false,
              cardType: 1
            }
        }
      } else {
        switch (index % sampleSize) {
          case 0:
            return {
              gridRow: 'span 2',
              gridColumn: rowHasLatestNews ? 'span 4' : 'span 6',
              textOutside: false,
              cardType: 1,
              cardSize: '1/2'
            }
          case 1:
            return {
              gridRow: 'span 2',
              gridColumn: 'span 3',
              textOutside: false,
              cardType: 2,
              cardSize: '1/3'
            }
          case 2:
          case 3:
            return {
              gridRow: 'span 1',
              gridColumn: 'span 3',
              textOutside: false,
              cardType: 1,
              cardSize: '1/4'
            }
          default:
            return {
              gridRow: 'span 1',
              gridColumn: 'span 1',
              textOutside: false,
              cardType: 1
            }
        }
      }
    } else if (size === 'm') {
      switch (index % sampleSize) {
        case 0:
          return {
            gridRow: 'span 3',
            gridColumn: rowHasLatestNews ? 'span 5' : 'span 7',
            textOutside: false,
            cardType: 1
          }
        case 1:
          return {
            gridRow: 'span 3',
            gridColumn: rowHasLatestNews ? 'span 4' : 'span 5',
            textOutside: false,
            cardType: 2
          }
        case 2:
        case 3:
          return {
            gridRow: 'span 3',
            gridColumn: 'span 6',
            textOutside: false,
            cardType: 1
          }
        default:
          return {
            gridRow: 'span 1',
            gridColumn: 'span 1',
            textOutside: false,
            cardType: 1
          }
      }
    }
    if (size === 's') {
      switch (index % sampleSize) {
        case 0:
        case 3:
          return {
            gridRow: 'span 3',
            gridColumn: 'span 12',
            textOutside: false,
            cardType: 1
          }
        default:
          return {
            gridRow: 'span 4',
            gridColumn: 'span 6',
            textOutside: true,
            cardType: 2
          }
      }
    } else {
      return {
        gridRow: 'span 1',
        gridColumn: 'span 1',
        textOutside: false,
        cardType: 1
      }
    }
  } else if (rowLength === 1) {
    if (size === 'm') {
      return {
        gridRow: 'span 3',
        gridColumn: rowHasLatestNews ? 'span 9' : 'span 12',
        textOutside: false,
        cardType: 3,
        cardSize: '1'
      }
    } else {
      return {
        gridRow: 'span 3',
        gridColumn: rowHasLatestNews ? 'span 10' : 'span 12',
        textOutside: false,
        cardType: 3,
        cardSize: size === 's' ? null : '1'
      }
    }
  } else if (rowLength === 2) {
    if (size === 's') {
      return {
        gridRow: 'span 4',
        gridColumn: 'span 6',
        textOutside: true,
        cardType: 1
      }
    } else if (size === 'xl' || size === 'l') {
      return {
        gridRow: 'span 2',
        gridColumn: rowHasLatestNews ? 'span 5' : 'span 6',
        textOutside: false,
        cardType: 1,
        cardSize: '1/2'
      }
    } else {
      return {
        gridRow: 'span 2',
        gridColumn: rowHasLatestNews ? 'span 8' : 'span 6',
        textOutside: false,
        cardType: 1,
        cardSize: '1/2'
      }
    }
  } else if (rowLength === 3) {
    if (size === 'l' || size === 'xl') {
      return {
        gridRow: 'span 2',
        gridColumn: rowHasLatestNews ? 'span 3' : 'span 4',
        textOutside: false,
        cardType: 2,
        cardSize: '1/3'
      }
    } else if (size === 'm') {
      return {
        gridRow: 'span 3',
        gridColumn: rowHasLatestNews ? 'span 3' : 'span 4',
        textOutside: false,
        cardType: 2,
        cardSize: '1/3'
      }
    } else if (size === 's') {
      switch (index % sampleSize) {
        case 0:
        case 1:
          return {
            gridRow: 'span 4',
            gridColumn: 'span 6',
            textOutside: true,
            cardType: 2
          }
        default:
          return {
            gridRow: 'span 4',
            gridColumn: 'span 12',
            textOutside: false,
            cardType: 1
          }
      }
    } else {
      return {
        gridRow: 'span 3',
        gridColumn: 'span 4',
        textOutside: false,
        cardType: 1
      }
    }
  } else if (rowLength === 5) {
    return {
      gridRow: 'span 1',
      gridColumn: 'span 2',
      textOutside: false,
      cardType: 5
    }
  } else {
    return {
      gridRow: 'span 3',
      gridColumn: 'span 6',
      textOutside: false,
      cardType: 1
    }
  }
}

export const getSize = width => {
  if (checkLandscape() || width < breakpoints.m) {
    return 's'
  } else if (width < breakpoints.l) {
    return 'm'
  } else if (width < breakpoints.xl) {
    return 'l'
  } else {
    return 'xl'
  }
}

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

export const calculateDescriptionHeight = (
  textContainerRef,
  titleRef,
  spaceAfterDescRef,
  spaceBetweenTitleAndDescRef,
  state,
  BODY_LINE_HEIGHT
) => {
  if (
    textContainerRef.current &&
    titleRef.current &&
    spaceAfterDescRef.current &&
    spaceBetweenTitleAndDescRef.current &&
    state.bodyHeight === 0
  ) {
    const node = textContainerRef.current
    const paddingTop = parseInt(getComputedStyle(node).paddingTop)
    const paddingBottom = parseInt(getComputedStyle(node).paddingBottom)

    const textContainerHeight = node.clientHeight - paddingTop - paddingBottom
    const titleHeight = titleRef.current.clientHeight
    const spaceBetweenTitleAndDescHeight =
      spaceBetweenTitleAndDescRef.current.clientHeight
    const spaceAfterDescHeight = spaceAfterDescRef.current.clientHeight

    // formula to calculate the description height is as below
    // container height (without padding) - title height - space between title and desc - space after the desc
    const descriptionHeight =
      textContainerHeight -
      (titleHeight + spaceBetweenTitleAndDescHeight + spaceAfterDescHeight)

    const lines = Math.floor(descriptionHeight / BODY_LINE_HEIGHT)
    return { bodyHeight: descriptionHeight, lines }
  }

  return null
}
