import round from './round'
import {
  ASPECT_RATIO_1,
  ASPECT_RATIO_2,
  ASPECT_RATIO_3
} from '../constants/aspectRatios.js'
import signImgix from './signImgix'

const maxImgDimensions = 600

export const getFocalPoint = (type, imageMeta, ratio) => {
  if (imageMeta) {
    if (ratio && ratio < ASPECT_RATIO_1) {
      return {
        x: imageMeta.fpX2,
        y: imageMeta.fpY2,
        z: imageMeta.zoom2
      }
    } else if (ratio && ratio < ASPECT_RATIO_2) {
      const factor =
        (ratio - ASPECT_RATIO_1) / (ASPECT_RATIO_2 - ASPECT_RATIO_1)
      return {
        x: round(imageMeta.fpX2 + (imageMeta.fpX - imageMeta.fpX2) * factor),
        y: round(imageMeta.fpY2 + (imageMeta.fpY - imageMeta.fpY2) * factor),
        z: round(imageMeta.zoom2 + (imageMeta.zoom - imageMeta.zoom2) * factor)
      }
    } else if (ratio && ratio < ASPECT_RATIO_3) {
      const factor =
        (ratio - ASPECT_RATIO_2) / (ASPECT_RATIO_3 - ASPECT_RATIO_2)
      return {
        x: round(imageMeta.fpX + (imageMeta.fpX3 - imageMeta.fpX) * factor),
        y: round(imageMeta.fpY + (imageMeta.fpY3 - imageMeta.fpY) * factor),
        z: round(imageMeta.zoom + (imageMeta.zoom3 - imageMeta.zoom) * factor)
      }
    } else if (ratio) {
      return {
        x: imageMeta.fpX3,
        y: imageMeta.fpY3,
        z: imageMeta.zoom3
      }
    }
  }

  if (imageMeta && imageMeta.fpX) {
    const fpData = (cardType = type) => {
      switch (cardType) {
        case 1:
        default:
          return {
            x: imageMeta.fpX,
            y: imageMeta.fpY,
            z: imageMeta.zoom
          }
        case 2:
          return {
            x: imageMeta.fpX2,
            y: imageMeta.fpY2,
            z: imageMeta.zoom2
          }
        case 3:
          return {
            x: imageMeta.fpX3,
            y: imageMeta.fpY3,
            z: imageMeta.zoom3
          }
      }
    }

    return fpData()
  }
  return null
}
/*
 * For article page and social media sharing, always take image layout 3 of CMS
 */
export const getFocalPointForArticle = (imageMeta) => {
  if (imageMeta) {
    return {
      x: imageMeta.fpX3,
      y: imageMeta.fpY3,
      z: imageMeta.zoom3
    }
  }

  return null
}

const getImgixUrl = ({
  cardType,
  imageMeta,
  src,
  imageQuality,
  imageDpr,
  newWidth,
  newHeight,
  isArticle = false
}) => {
  const fp = isArticle
    ? getFocalPointForArticle(imageMeta)
    : getFocalPoint(cardType, imageMeta, newWidth / newHeight)

  const fpString = fp
    ? `&fp-x=${fp.x}&fp-y=${fp.y}&fp-z=${fp.z}&fit=crop&crop=focalpoint`
    : ''

  const [imageWidth, imageHeight] = ((newWidth, newHeight) => {
    const [cw, ch] = [Math.floor(newWidth), Math.floor(newHeight)]

    if (cw <= maxImgDimensions && ch <= maxImgDimensions) {
      return [cw, ch]
    }

    const aspectRatio = cw / ch
    const landscape = cw > ch
    const [length, breadth] = [600, Math.floor(600 / aspectRatio)]

    return landscape ? [length, breadth] : [breadth, length]
  })(newWidth, newHeight)

  const imageUrl = `${src}?w=${
    imageWidth || 1200
  }&h=${imageHeight}&q=${imageQuality}&dpr=${imageDpr}${fpString}&fm=webp&auto="format"`

  return { url: signImgix(imageUrl), width: imageWidth, height: imageHeight }
}

export default getImgixUrl
