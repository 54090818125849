import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { Link } from 'gatsby'

import {
  Col,
  Condition,
  Row,
  FirstRow,
  Space,
  Temperature,
  WeatherContainer,
  WeatherName,
  WeatherSvgIcon
} from './weather.styled'

const Weather = ({ getWeather, weather, createNotification }) => {
  useEffect(() => {
    getWeather()
    const interval = setInterval(() => {
      getWeather()
    }, 1000 * 60 * 10)

    return () => clearInterval(interval)
  }, [])

  const { data } = weather

  if (!data) {
    return null
  }

  return (
    <Link
      to="/weather"
      style={{
        textDecoration: 'none'
      }}
    >
      <WeatherContainer id="weather">
        <Space />
        <Col>
          <FirstRow>
            <Space />
            <WeatherSvgIcon dangerouslySetInnerHTML={{ __html: data.icon }} />
            <WeatherName>{data.name || ''}</WeatherName>
            <Space />
          </FirstRow>
          <Row>
            <Condition> {data.description || ''} </Condition>
            <Temperature>{data.temp ? data.temp.now : ''}</Temperature>
          </Row>
        </Col>
        {/* <button onClick={() => createNotification()}>Send</button> */}
      </WeatherContainer>
    </Link>
  )
}

const mapStateToProps = ({ weather }) => {
  return { weather }
}

const mapDispatchToProps = dispatch => {
  return {
    getWeather: () => {
      dispatch({ type: 'GET_WEATHER' })
    }
    // createNotification: () => {
    //   const payload = { text: "Nice new notification" }
    //   dispatch({ type: "NEW_NOTIFICATION", payload })
    // },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Weather)
