import React from "react"

import {
  Error,
  Label,
  StyledInput,
  LabelContainer,
  StatusLabel,
} from "./styles"

const Input = ({
  value = "",
  onChange,
  label,
  required = false,
  error,
  ...rest
}) => (
  <div>
    <LabelContainer>
      <Label>{label}</Label>
      {required && <StatusLabel>مطلوب</StatusLabel>}
    </LabelContainer>

    <StyledInput
      {...rest}
      hasError={!!error}
      value={value}
      onChange={e => onChange(e.target.value)}
    />

    {error && <Error>{error}</Error>}
  </div>
)

export default Input
