import React from 'react'
import lozad from 'lozad'
import Helmet from 'react-helmet'
import classnames from 'classnames'
import getImgixUrl from '../../utils/getImgixUrl'
import gridStyles from './background.module.scss'

/**
 * @class Picture
 */
class Picture extends React.Component {
  constructor() {
    super()
  }

  componentDidMount() {
    lozad().observe()
  }

  render() {
    const { props } = this
    const { newWidth, newHeight, customImageClass = '' } = props

    const image =
      props.imageUrl == null
        ? newWidth == null
          ? null
          : getImgixUrl({ ...props, newHeight, newWidth }).url
        : props.imageUrl

    return (
      <>
        <img
          alt={props.title}
          width="100%"
          loading={'eager'}
          data-src={image}
          className={classnames(
            gridStyles[props.fullScreen ? 'fullScreenImage' : 'image'],
            gridStyles[props.templateNumber === 3 ? 'imageTemplate3' : ''],
            'lozad',
            'articleBanner',
            customImageClass
          )}
        />
      </>
    )
  }
}
export default Picture
