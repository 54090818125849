import React from 'react'
import TimeAgo from 'javascript-time-ago'
import ar from 'javascript-time-ago/locale/ar'
import { parseISO, differenceInHours, isSameDay } from 'date-fns'
import DateTimeService from '../../utils/common'

TimeAgo.addLocale(ar)
const timeAgo = new TimeAgo('ar-LY')

const renderDate = (date) => {
  const now = new Date()
  const articleDate = parseISO(date)

  if (differenceInHours(now, articleDate) <= 3) {
    return timeAgo.format(new Date(date))
  }

  if (isSameDay(now, articleDate)) {
    return (
      <time dateTime={DateTimeService.formattedDateTime(articleDate)}>
        {DateTimeService.formatInLocalTime(date, 'HH:mm')}
      </time>
    )
  }

  return (
    <time dateTime={DateTimeService.formattedDateTime(articleDate)}>
      {DateTimeService.formatInLocalTime(date, 'dd MMM yyyy HH:mm')}
    </time>
  )
}

export default renderDate
