/*
This plugin extends lazySizes to lazyLoad:
background images, videos/posters and scripts

Background-Image:
For background images, use data-bg attribute:
<div class="lazyload" data-bg="bg-img.jpg"></div>

 Video:
 For video/audio use data-poster and preload="none":
 <video class="lazyload" preload="none" data-poster="poster.jpg" src="src.mp4">
 <!-- sources -->
 </video>

 For video that plays automatically if in view:
 <video
	class="lazyload"
	preload="none"
	muted=""
	data-autoplay=""
	data-poster="poster.jpg"
	src="src.mp4">
</video>

 Scripts:
 For scripts use data-script:
 <div class="lazyload" data-script="module-name.js"></div>


 Script modules using require:
 For modules using require use data-require:
 <div class="lazyload" data-require="module-name"></div>
*/

;(function(window, factory) {
  if (!window) return

  var globalInstall = function() {
    factory(window.lazySizes)
    window.removeEventListener("lazyunveilread", globalInstall, true)
  }

  factory = factory.bind(null, window, window.document)

  if (typeof module == "object" && module.exports) {
    factory(require("lazysizes"))
  } else if (window.lazySizes) {
    globalInstall()
  } else {
    window.addEventListener("lazyunveilread", globalInstall, true)
  }
})(typeof window === "undefined" ? null : window, function(
  window,
  document,
  lazySizes
) {
  /*jshint eqnull:true */

  var bgLoad, regBgUrlEscape
  var uniqueUrls = {}

  if (document.addEventListener) {
    regBgUrlEscape = /\(|\)|\s|'/

    bgLoad = function(url, cb) {
      var img = document.createElement("img")
      img.onload = function() {
        img.onload = null
        img.onerror = null
        img = null
        cb()
      }
      img.onerror = img.onload

      img.src = url

      if (img.complete && img.onload) {
        img.onload()
      }
    }

    window.addEventListener(
      "lazybeforeunveil",
      function(e) {
        // eslint-disable-next-line
        if (e.detail.instance != lazySizes) {
          return
        }

        var tmp, load, bg, poster
        if (!e.defaultPrevented) {
          var target = e.target

          if (target.preload === "none") {
            target.preload = target.getAttribute("data-preload") || "auto"
          }

          if (target.getAttribute("data-autoplay") != null) {
            if (target.getAttribute("data-expand") && !target.autoplay) {
              try {
                target.play()
              } catch (er) {}
            } else {
              requestAnimationFrame(function() {
                target.setAttribute("data-expand", "-10")
                lazySizes.aC(target, lazySizes.cfg.lazyClass)
              })
            }
          }

          tmp = target.getAttribute("data-link")
          if (tmp) {
            addStyleScript(tmp, true)
          }

          // handle data-script
          tmp = target.getAttribute("data-script")
          if (tmp) {
            addStyleScript(tmp)
          }

          // handle data-require
          tmp = target.getAttribute("data-require")
          if (tmp) {
            if (lazySizes.cfg.requireJs) {
              lazySizes.cfg.requireJs([tmp])
            } else {
              addStyleScript(tmp)
            }
          }

          // handle data-bg
          bg = target.getAttribute("data-bg")
          if (bg) {
            e.detail.firesLoad = true
            load = function() {
              target.style.backgroundImage =
                "url(" +
                (regBgUrlEscape.test(bg) ? JSON.stringify(bg) : bg) +
                ")"
              e.detail.firesLoad = false
              lazySizes.fire(target, "_lazyloaded", {}, true, true)
            }

            bgLoad(bg, load)
          }

          // handle data-poster
          poster = target.getAttribute("data-poster")
          if (poster) {
            e.detail.firesLoad = true
            load = function() {
              target.poster = poster
              e.detail.firesLoad = false
              lazySizes.fire(target, "_lazyloaded", {}, true, true)
            }

            bgLoad(poster, load)
          }
        }
      },
      false
    )
  }

  function addStyleScript(src, style) {
    if (uniqueUrls[src]) {
      return
    }
    var elem = document.createElement(style ? "link" : "script")
    var insertElem = document.getElementsByTagName("script")[0]

    if (style) {
      elem.rel = "stylesheet"
      elem.href = src
    } else {
      elem.src = src
    }
    uniqueUrls[src] = true
    uniqueUrls[elem.src || elem.href] = true
    insertElem.parentNode.insertBefore(elem, insertElem)
  }
})
