import React, { useEffect, useState } from 'react'
import { navigate, Link } from 'gatsby'

import base from '../../../sagas/base'
import transformArticle from '../../../utils/transform-article-data'
import transformHeadline from '../../../utils/transform-headline'
import BackgroundCover from '../../background/background'
import useViewportSize from '../../../utils/useViewportSize'
import { breakpoints } from '../../../constants/app'

import renderDate from '../renderDate'
import {
  Container,
  ShowResults,
  Suggestions,
  OldArticles,
  OldArticleRow,
  OldArticleImage
} from './styles'
import searchIcon from '../assets/search.svg'
import historyIcon from '../assets/history-icon.png'
import arrowIcon from '../assets/red-arrow.svg'

const HEADLINE_DEFAULT_IMAGE =
  'https://img-staging.asharq.com/30931574-cdd2-4bed-85e6-9d6b8b8d8933.png?w=145&h=152&dpr=2&fit=crop&ixlib=js-2.3.1&s=c44fc81b29aea368b4ef04e57cf511a9'

const Results = ({
  visible,
  search,
  language = 'ar',
  fromSideMenu = false,
  closeModal,
  debouncedSendEventSearch = () => {},
  setSearch = () => {}
}) => {
  const [suggestions, setSuggestions] = useState([])
  const [history, setHistory] = useState([])
  const [articles, setArticles] = useState([])
  const { width } = useViewportSize()

  useEffect(() => {
    if (search && visible) {
      const requests = [
        base.get('/search/autocomplete', { q: search, size: 3 }),
        base.get('/search/history', { size: 50 })
      ]

      Promise.all(requests).then(([autocompleteRes, historyRes]) => {
        const noOfHistoryItems = !fromSideMenu && width >= breakpoints.m ? 6 : 3
        const autocompleteData = autocompleteRes.data.data.slice(0, 2)
        let { words, items } = historyRes.data.data
        const historyData = words.slice(0, 2)
        items = items
          .map((article) =>
            article.item_type === 'headline'
              ? transformHeadline({ lang: language, article })
              : transformArticle({ lang: language, article })
          )
          .slice(0, noOfHistoryItems)

        setSuggestions(autocompleteData)
        setHistory(historyData)
        setArticles(items)
      })
    }
  }, [search, visible])

  const handleSearch = (term) => {
    setSearch(term)
    setTimeout(() => {
      debouncedSendEventSearch(term)
      // G360 Event for Search
      dataLayer.push({
        event: 'asEvent',
        analyticsEventLabel: 'Search',
        eventCategory: 'Search',
        eventAction: 'Search',
        eventLabel: ''
      })
      navigate(`/${language}/search/?query=${term}`)
    }, 100)
  }

  if (!visible) {
    return null
  }

  const getLink = (ar) => {
    if (ar.type === 'headline') {
      return `/latest-news/${ar.id}`
    }

    if (ar.isShortHandArticle) {
      return `/specials/${ar.slug}`
    }

    return `/ar/${ar.slug}`
  }

  return (
    <Container fromSideMenu={fromSideMenu}>
      <ShowResults>
        <div
          onClick={() => {
            navigate(`/${language}/search/?query=${search}`)
            if (closeModal) {
              closeModal()
            }
          }}
        >
          عرض جميع النتائج لــ
          <span>“{search}“</span>
          <img src={arrowIcon} loading="lazy" alt="Arrow Icon" />
        </div>
      </ShowResults>

      <Suggestions>
        {history.map((h, i) => (
          <div key={i}>
            <div
              className="link-class"
              onClick={() => {
                handleSearch(h)
                if (closeModal) {
                  closeModal()
                }
              }}
            >
              <img
                src={historyIcon}
                className="history"
                loading="lazy"
                alt="History"
              />
              {h}
            </div>
          </div>
        ))}

        {suggestions.map((s, i) => (
          <div key={i}>
            <div
              className="link-class"
              onClick={() => {
                handleSearch(search + s)
                if (closeModal) {
                  closeModal()
                }
              }}
            >
              <img
                src={searchIcon}
                className="search"
                loading="lazy"
                alt="Search Icon"
              />
              {search + s}
            </div>
          </div>
        ))}
      </Suggestions>

      {!!articles.length && (
        <OldArticles fromSideMenu={fromSideMenu}>
          {articles.map((ar) => (
            <Link key={ar.id} to={getLink(ar)} state={{ fromSearch: true }}>
              <OldArticleRow>
                <div className="text-container">
                  <div className="title">{ar.title}</div>
                  <div className="date">
                    {renderDate(ar.publishedAt || ar.createdAt || ar.updatedAt)}
                  </div>
                </div>

                <OldArticleImage>
                  <BackgroundCover
                    title={ar.title}
                    articleId={ar.id}
                    src={
                      ar.type === 'headline'
                        ? HEADLINE_DEFAULT_IMAGE
                        : ar.mainImageUrl
                    }
                    disableVideoClick
                    imageQuality={90}
                    imageDpr={2}
                    videoSrc={ar.mainVideoUrl}
                    forceVideo={false}
                    fullScreen={false}
                    imageMeta={ar.mainImage}
                    disableControls={true}
                    autoplay={false}
                    lazyload={true}
                    imageUrl={
                      ar.type === 'headline' ? HEADLINE_DEFAULT_IMAGE : null
                    }
                    style={
                      ar.type === 'headline'
                        ? { backgroundColor: 'rgb(235, 235, 235)' }
                        : { borderRadius: '0px 0px 0px 10px' }
                    }
                    showPlayIcon
                  />
                </OldArticleImage>
              </OldArticleRow>
            </Link>
          ))}
        </OldArticles>
      )}
    </Container>
  )
}

export default Results
