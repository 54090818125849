import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import LiveTvPlayer from '../../liveTvPlayer'
import {
  QuickLinkContainer,
  LiveTvMobileView,
  SpinnerContainer
} from './quicklinks.styled'
import { breakpoints } from '../../../constants/app'
import { Spinner } from '../../grid/grid.styled'
import testMobile from '../../../utils/testMobile'
import useViewportSize from '../../../utils/useViewportSize'
import { getSize } from '../../grid/helper'

import LiveTVHoc from '../../livetv/liveTvHoc'

const QuickLinks = ({
  data = [],
  isLatestNewsInViewport,
  toggleLiveTvMobileView
}) => {
  const [showLiveTv, setShowLiveTv] = useState(false)
  const { width } = useViewportSize()
  const gridSize = getSize(width)

  useEffect(() => {
    if (document.fullscreenElement === null) {
      setShowLiveTv(false)
      toggleLiveTvMobileView(false)
    }
  }, [gridSize])

  const showLiveTvMobileView = () => {
    if (window.location.pathname === '/live/') {
      return
    }

    if (showLiveTv || data.showLiveTvMobileInGrid) {
      return
    }

    if (isLatestNewsInViewport && width <= breakpoints.m) {
      toggleLiveTvMobileView(true)
      setShowLiveTv(false)
    } else {
      toggleLiveTvMobileView(false)
      setShowLiveTv(true)
    }
    pushGtmEvent()
  }

  const hideLiveTvMobileView = () => {
    setShowLiveTv(false)
    toggleLiveTvMobileView(false)
  }

  const pushGtmEvent = () => {
    dataLayer.push({
      event: 'gtm.click',
      category: 'Live TV'
    })
  }

  const renderLiveTvMobileView = () => {
    const { isAsharqAppPopupVisible = false } = data

    if (!showLiveTv) {
      return null
    }

    const newProps = {
      showSocialShareIcons: true,
      mobileView: true,
      onMobileViewClose: hideLiveTvMobileView
    }

    const topPosition = isAsharqAppPopupVisible ? '209px' : '119px'

    return (
      <LiveTvMobileView
        style={{
          top: topPosition
        }}
      >
        <LiveTVHoc
          showSearch
          {...newProps}
          liveTvComponent={<LiveTvPlayer {...newProps} />}
        />
      </LiveTvMobileView>
    )
  }
  const isMobile = testMobile()

  return (
    <QuickLinkContainer
      shouldShowScroll={
        data && data.quickLinks && data.quickLinks.length > 4 && isMobile
      }
    >
      {data.fetched && data.quickLinks.length ? (
        <>
          {data.quickLinks.map((item) => {
            if (item.url === '/live' || item.url === '/live/') {
              return (
                <React.Fragment key={item.id}>
                  {typeof window !== 'undefined' && (
                    <>
                      {!isMobile ? (
                        <Link to={item.url} key={item.id}>
                          <span>{item.name}</span>
                        </Link>
                      ) : (
                        <>
                          <div
                            key={item.id}
                            className="live-tv"
                            onClick={showLiveTvMobileView}
                          >
                            <span>{item.name}</span>
                          </div>
                          {renderLiveTvMobileView()}
                        </>
                      )}
                    </>
                  )}
                </React.Fragment>
              )
            } else {
              return (
                <React.Fragment key={item.id}>
                  {item.target === '_blank' ? (
                    <a
                      key={item.id}
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      {item.name}
                    </a>
                  ) : (
                    <Link to={item.url} key={item.id}>
                      <span>{item.name}</span>
                    </Link>
                  )}
                </React.Fragment>
              )
            }
          })}
        </>
      ) : (
        data.fetching && (
          <SpinnerContainer>
            <Spinner
              inlineProps={{
                height: 30,
                width: 30,
                marginTop: 5
              }}
            />
          </SpinnerContainer>
        )
      )}
    </QuickLinkContainer>
  )
}

export default QuickLinks
