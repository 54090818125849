import React from 'react'
import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

const calculateMarginTop = (
  isSmall = false,
  { isRecommendation, hasTopCategory, ignoreMarginTop = false, mode }
) => {
  if (isSmall && mode === 'article' && isRecommendation) {
    return '70px'
  }

  if (ignoreMarginTop) {
    return '0px'
  }
  if (isRecommendation) {
    return '150px'
  }

  if (hasTopCategory) {
    return isSmall ? '70px' : '32px'
  }
  return '0px'
}

export const GridDeleteCard = styled.div`
  z-index: 1000;
  position: absolute;
  background: #c41b19;
  margin: 10px;
  padding: 8px;
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`

export const GridDeleteIcon = styled.img`
  height: 15px;
  width: 15px;
`

export const GridTimeBox = styled.div`
  position: absolute;
  bottom: 10px;
  left: 15px;
  padding: 5px;
  z-index: 100000;
  background: #263238;
  font-size: 0.7em;
  display: flex;
  height: 12px;
  line-height: 11px;
`

export const CardsContainer = styled.div`
  margin-bottom: ${({ isCategory }) => (isCategory ? '70px' : '6px')};
  margin-top: ${(props) => calculateMarginTop(false, props)};
  display: grid;
  grid-template-columns: ${({ isVideoRecommendation }) =>
    isVideoRecommendation ? 'repeat(10, 1fr)' : 'repeat(12, 1fr)'};
  grid-gap: ${({ isVideoRecommendation }) =>
    isVideoRecommendation ? '10px' : '6px'};
  grid-auto-rows: 16vw;
  position: relative;
  padding-top: ${({ isRecommendation }) => (isRecommendation ? '40px' : '0')};

  + [data-ad-container],
  + .lazyload-wrapper + [data-ad-container] {
    min-height: 290px;
    margin-top: ${({ isCategory }) => (isCategory ? '-30px' : '30px')};
    margin-bottom: ${({ isCategory }) => (isCategory ? '60px' : '6px')};
  }

  @media ${device.s},
    (max-width: 926px) and (orientation: landscape),
    (max-width: 812px) and (orientation: landscape) {
    margin-top: ${(props) => calculateMarginTop(true, props)};
    padding-top: 0;

    + [data-ad-container] {
      min-height: 290px;
      margin-top: ${({ isCategory }) => (isCategory ? '-60px' : '0')};
      margin-bottom: ${({ isCategory }) => (isCategory ? '60px' : '6px')};
    }
  }

  @media ${device.m} {
    grid-auto-rows: ${({ rowHasVote }) => (rowHasVote ? '17vw' : '16vw')};
  }

  @media ${device.l} {
    grid-auto-rows: ${({ rowHasVote }) => (rowHasVote ? '25vw' : '16vw')};
  }

  @media ${device.xl} {
    grid-auto-rows: 260px;
  }

  page-break-inside: avoid;
`

export const GridAudioButton = styled.div`
  :hover {
    color: white;
  }
  font-family: VideoJS2;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
  color: #777;

  ${(props) =>
    props.templateNumber === 3
      ? `
  padding-left: 4px;
  margin-left: 3px;
    `
      : `

  position: absolute;
  top: 0px;
  left: 0px;
  width: 50px;
  height: 50px;
      `};
`

export const GridSearchScope = styled.div`
  color: #7d7d82;
  padding: 11px 15px;
  background: #eee;
  border-radius: 2px;
  margin-bottom: 20px;
  span {
    opacity: 0.6;
  }

  p {
    margin: 0;
    padding: 0;
  }
`

export const GridItemContentContainer = styled.div`
  overflow: hidden;
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: calc(100% - 40px);
`

export const GridItemContent = styled.div`
  position: absolute;
  padding: 20px;
  bottom: 10px;
  z-index: 101;
  animation: title-fade-in 2s;
  @media print {
    opacity: 1;
  }
`

export const GridCardVideoIcon = styled.div`
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  backgrond: red;
  top: 10px;
  left: 10px;
  z-index: 9999;
  opacity: 0.4;
`

const TitleBase = styled.h2`
  font-size: ${(props) => (props.isLarge ? '1.8em' : '1.3em')};
  margin: 0;
  padding-bottom: 0px;
  font-weight: bold;

  @media (max-width: 500px) {
    font-size: 4.5vw;
    padding-bottom: 0px;
  }

  @media (max-width: 900px) and (min-width: 500px) {
    font-size: 3.5vw;
  }

  @media print {
    font-size: 1em;
    color: white;
    margin: 0;
    padding-bottom: 0px;
    font-weight: bold;
  }
`

export const TemplateCardTitle = styled(TitleBase)`
  color: rgb(86, 86, 86);
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-size: 26px;
`

export const GridItemTitle = styled(TitleBase)`
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9);

  @media (max-width: 500px) {
    text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
  }
`

export const GridItemMeta = styled.span`
  color: white;
  opacity: 0.6;
  font-size: 0.9em;

  @media (max-width: 500px) {
    display: none;
  }
`

export const GridCard = styled.div`
  position: relative;
  grid-column: ${(props) => props.gridColumn || 'auto'};
  grid-row: ${(props) => props.gridRow || 'auto'};
  background: ${(props) => props.background || 'auto'};

  transition: opacity 0.4s ease-in-out;
  opacity: 1;

  border-radius: 0 15px;
  overflow: hidden;

  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);

  z-index: 100;

  &.ad-fallback-cell {
    display: none;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    color: #fff;
  }

  @media ${device.s} {
    grid-row: ${(props) => {
      if (props.templateNumber === 4) {
        return 'span 5'
      } else if (props.templateNumber === 6) {
        return 'span 4'
      } else if (props.templateNumber === 2) {
        return 'span 3'
      } else {
        return 'auto'
      }
    }};

    &.inlineAdCard {
      grid-row: span 4;
      text-align: center;
      box-shadow: none;
      display: flex;
      align-self: center;
      justify-self: center;
    }
  }

  ul,
  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  ul {
    color: rgb(51, 51, 51);
  }
`

export const GridAdCard = styled.div`
  min-height: 260px;
  position: relative;
  grid-column: span 12;
  grid-row: auto;

  transition: opacity 0.4s ease-in-out;
  opacity: 1;

  border-radius: 0;
  overflow: hidden;

  box-shadow: none;

  z-index: 100;

  a {
    display: block;
    width: 100%;
    height: 100%;
    color: #fff;
  }

  @media ${device.s} {
    grid-row: span 4;
    padding-bottom: 10px;
    border-bottom: 1px solid #cac5c5;

    &.inlineAdCard {
      grid-row: span 4;
      text-align: center;
      box-shadow: none;
      display: flex;
      align-self: center;
      justify-self: center;

      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  ul,
  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  ul {
    color: rgb(51, 51, 51);
  }
`

export const GridCardHover = styled(GridCard)`
  :hover {
    cursor: pointer;
  }
`

export const GridLoader = styled.div`
  grid-column: 1/-1;
  text-align: center;
  padding-top: 50px;
`

export const GridContainer = styled.div`
  min-height: 80vh;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;

  .infinite-scroll-component {
    overflow: unset !important;
  }

  @media ${device.tabletPortrait} {
    #latestNewsWrapper {
      margin: 10px 0 70px !important;
    }
  }
`

const GridBackgroundBase = styled.div`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
`

export const TemplateCardBackground = styled(GridBackgroundBase)`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection === 'column' ? 'column' : 'row'};
`

export const GridBackground = styled(GridBackgroundBase)`
  // disable grid gradient background for now

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30%;
    @media print {
      height: 0%;
    }
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0.08) 50%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 102;
    border-top-right-radius: 15px;
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    @media print {
      height: 0%;
    }

    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0.2) 40%,
      rgba(0, 0, 0, 0) 80%
    );
    z-index: 102;
    border-bottom-left-radius: 15px;
  }
`

export const GridCardLink = styled.div`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 102;
  :hover {
    cursor: ${(props) => (props.isRearrange ? 'move' : 'unset')};
  }
`

export const GridCardContent = styled.div`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
`
export const GridOverlayStats = styled.div`
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 10px;
  font-size: 0.75em;
  left: 0;
  right: 0;
  bottom: 0;
  direction: ltr;
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 0%) 0%,
    rgb(0 0 0 / 41%) 30%,
    rgb(0 0 0 / 70%) 100%
  );

  &.whiteBg {
    background: none;
  }

  @media ${device.s} {
    justify-content: flex-end;
    bottom: -4px;
  }
`
export const GridContentCount = styled.div`
  font-size: 0.75em;
  color: ${({ color }) => color || '#ccc'};
  direction: ltr;
`
export const GridContentRecommended = styled.div`
  font-size: 0.75em;
  color: ${({ color }) => color || '#ccc'};
`
export const GridContentTimestamp = styled.span`
  color: ${({ color }) => color || '#b7b4b4'};
`

export const GridError = styled.div`
  text-align: center;
  color: #666;
  padding: 20px 0;
`

export const GridOutsideTitle = styled.div`
  background: #494949;
  color: #fff;
  height: 40%;
  display: flex;
  align-items: center;

  span {
    padding: 0 10px;
    display: block;
    font-weight: normal;
    font-size: 0.95em;
    margin-top: -3px;
  }
`

export const Spinner = (props) => (
  <StyledSpinner viewBox="0 0 50 50" style={{ ...(props.inlineProps || '') }}>
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="4"
    />
  </StyledSpinner>
)

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: #ccc;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`

export const CategoryFooter = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: -140px;

  @media (max-width: 768px) {
    top: -153px;
  }
`

export const CategoryLabel = styled.div`
  @media ${device.s},
    (max-width: 812px) and (orientation: landscape),
    (max-width: 896px) and (orientation: landscape),
    (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    font-size: 20px;
    top: -41px;
    border-top: 2px solid #c41b1a;
    border-right: 2px solid #c41b1a;
    border-radius: 0 15px 0 0px;
    a,
    .notLinkable {
      padding: 5px 22px 33px 18px;
    }
  }

  overflow: hidden;

  height: 44px;
  position: absolute;
  z-index: 99;
  top: ${(props) => (props.isRecommendation ? '-7px' : '-47px')};
  right: 16px;
  background-color: #fff;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0 18px 0 0px;
  font-family: 'Bloomberg Arabic Beta v4';
  font-weight: bold;
  font-size: 24px;
  line-height: 1.5em;
  border-top: 3px solid #c41b1a;
  border-right: 3px solid #c41b1a;
  :hover {
    cursor: ${(props) => (props.isLinkable ? 'pointer' : 'unset')};
    user-select: none;
    background-color: #c41b19;
    color: #fff;
    .whiteArrow {
      display: flex;
    }
    .redArrow {
      display: none;
    }
  }

  .whiteArrow {
    display: none;
  }

  background-color: ${(props) => (props.isLinkable ? '#fff' : '#c41b19')};
  color: ${(props) => (props.isLinkable ? '#c41b19' : '#fff')};

  :hover {
    background-color: #c41b19;
    a,
    .notLinkable {
      color: #fff;
    }
  }

  .notLinkable {
    color: #fff !important;
    background-color: #c41b19;
  }

  a,
  .notLinkable {
    padding: 5px 22px 12px 22px;
    text-decoration: none;
    color: #c41b19;
    display: flex;
  }
`

export const GridCardId = styled.div`
  :hover {
    cursor: pointer;
  }
  position: absolute;
  z-index: 1000;
  left: 10px;
  background: white;
  top: 5px;
  padding: 5px;
  font-size: 10px;
`

export const ImgContainer = styled.div`
  display: flex;

  img {
    width: 11px;
    height: 36px;
  }
`
export const CategoryLabelImg = styled.img`
  display: flex;
  margin-right: 24px;
`
export default Spinner

export const TextContainer = styled.div`
  background: white;
  padding: ${(props) =>
    props.templateNumber === 4 ? 'unset' : '12px 14px 12px 14px'};
  display: flex;
  flex-direction: column;
  ${(props) => (props.f1 ? 'flex : 1;' : '')}
  overflow: hidden;
`

export const TextContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 14px 12px 14px;
  flex: 1;
`

export const TextContainerForType2 = styled(TextContainer)`
  width: 100%;
  height: 100%;
  flex: 1;
  padding: unset;
  padding-top: 40px;
  box-sizing: border-box;

  @media ${device.s} {
    padding-top: unset;
    box-sizing: unset;
  }
`

export const SpaceBetweenTitleAndDescription = styled.div`
  height: 12px;

  @media ${device.s} {
    height: 9px;
  }
`
export const DateContainer = styled.div`
  font-size: 12px;
  text-align: right;
  font-family: 'Neue Haas Grotesk Bloomberg';
`

export const Duration = styled.span`
  font-size: 14px;
  font-family: 'Neue Haas Grotesk Bloomberg';
  padding-top: 3px;
  margin-left: 10px;
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  margin-top: 5px;
`

export const GridMobileBackground = styled(GridBackgroundBase)`
  display: flex;
  flex-direction: column;

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30%;
    @media print {
      height: 0%;
    }
    z-index: 102;
    border-top-right-radius: 15px;
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    @media print {
      height: 0%;
    }
    z-index: 102;
    border-bottom-left-radius: 15px;
  }
`

export const BannerGrid = styled.div`
  text-align: center;

  @media ${device.s} {
    min-height: 280px; // TODO: remove
    overflow: hidden;
    margin-bottom: 6px;
    margin-top: 0px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 6px;
    grid-auto-rows: 16vw;
    position: relative;

    &[data-page-name='category'] {
      display: block;
    }

    &.grid-row {
      margin: 0;
    }
  }

  &.topBanner {
    margin-bottom: 60px;
  }

  &.banner-grid-row {
    padding: 40px 0 25px 0;
  }

  &.topRow {
    padding-top: 30px;
    margin-top: -70px;
    margin-bottom: ${({ position }) => (position === 'top' ? 0 : '70px')};
  }
`
