import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const Ring = styled.div`
  border: 3px solid rgba(255, 255, 255, 0.322);
  background: #c42224;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  animation: pulse 2s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
`
export const Circle = styled.div`
  width: 8px;
  height: 8px;
  background-color: #c42224;
  border-radius: 50%;
  position: relative;
  top: calc(50% - 25px);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
`
export const LiveIconContainer = styled.div`
  width: 26px;
  height: 26px;
`

export const Label = styled.span`
  padding-left: 6px;
  color: ${props =>
    props.isDarkLabel ? 'rgb(51, 51, 51)' : 'rgb(255, 255, 255)'};
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-weight: 400;
  font-size: 15px;
  text-shadow: ${props =>
    props.isDarkLabel ? 'none' : 'rgba(0, 0, 0, 0.6) 1px 1px 3px'};

  @media ${device.s} {
    font-size: 12px;
  }
`
