import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const ImageWrapper = styled.div`
  height: 100%;

  @media (max-width: 768px) {
    padding: ${(props) => (props.padding ? `${props.padding}px` : '0')};
    background: white;
    box-sizing: ${(props) =>
      props.isBorderBox ? 'border-box' : 'content-box'};
  }
`

export const ArticleImageWrapper = styled.div`
  height: 100%;
  max-height: 100%;

  @media ${device.s} {
    min-height: 200px;
  }

  @media ${device.m} {
    min-height: 450px;
  }

  @media ${device.l} {
    min-height: 450px;
  }

  @media ${device.xl} {
    min-height: 600px;
  }

  @media (max-width: 768px) {
    padding: ${(props) => (props.padding ? `${props.padding}px` : '0')};
    background: white;
    box-sizing: ${(props) =>
      props.isBorderBox ? 'border-box' : 'content-box'};
  }
`

export const VideoWrapper = styled.div`
  pointer-events: ${(props) => (props.pointerEvents ? 'none' : 'auto')};
  position: relative;
  height: ${(props) => props.height || '100%'};
  width: ${(props) => props.width || '100%'};
  background: #494949;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    background: white;
  }
`

export const VideoInner = styled.div`
  ${(props) =>
    props.templateNumber === 3
      ? `
  width: 100%;
  height: 100%;

  @media ${device.s} {
    padding: 10px;
    box-sizing: border-box;
    border-radius: 0 14px;
  }
`
      : `
  width: 100%;
  height: 100%;
      `}

  ${(props) =>
    props.isArticle &&
    `
    @media ${device.xl} {
      height: 720px;
    }
    @media ${device.l} {
      height: 560px;
    }
    @media ${device.m} {
      height: 420px;
    }
      @media ${device.s} {
        height: 192px;
      }
      `}
`

export const BackgroundContainer = styled.div`
  display: block;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  border-radius: ${(props) => (props.noBorderRadius ? 'unset' : '0 14px')};
  overflow: hidden;
  position: relative;
  background: #494949;

  .photoArticle & {
    border-radius: 0;
  }

  @media ${device.s} {
    height: ${(props) =>
      props.templateNumber === 4 ? '60%' : props.height || '100%'};
    margin-top: ${(props) => (props.fullScreen ? '20px' : '0px')};

    .photoArticle & {
      margin-top: 0;
      border-radius: 0;
    }
  }

  // opacity: ${(props) => (props.inView ? 1 : 0)};

  div {
    transition: all 0.4s ease;
  }

  ${({ cardType }) =>
    cardType === 1 &&
    `
    &:before {
      position:absolute;
      top: 0;
      left: 0;
      color: yellow;
      content: "1"
    }
`}

  ${({ cardType }) =>
    cardType === 2 &&
    `
    &:before {
      position:absolute;
      top: 0;
      left: 0;
      color: yellow;
      content: "2"
    }
`}

${({ cardType }) =>
    cardType === 3 &&
    `
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      color: yellow;
      content: "3"
    }
`}
`

export const PlayIcon = styled.img`
  width: 29px;
  height: 29px;
  position: absolute;
  left: 17px;
  bottom: 17px;
`
