/* eslint-disable quote-props */
import { Location } from '@reach/router'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'

import {
  BookmarkIcon,
  LoveIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsAppIcon,
  EmailIcon,
  ShareIcon
} from './icons'
import {
  SocialControls,
  SocialOption,
  SocialLabel,
  SocialControlsContainer
} from './socialcontrols.styled'
import {
  sendEventBookmark,
  sendEventReact,
  sendEventShare
} from '../../utils/sendEvent'
import base from '../../sagas/base'
import testMobile, { testTablet } from '../../utils/testMobile'

const ssrMode = typeof window === 'undefined'

if (!ssrMode) {
  window.dataLayer = window.dataLayer || []
}

const Card = (props) => {
  const {
    itemType,
    changeLike,
    changeBookmark,
    theme,
    themehover,
    label,
    article = {},
    page,
    showBookmark = true,
    showLike = true,
    isVerticallyAligned = false,
    showWhatsApp = true,
    activeTheme,
    customShareUrl,
    subMode
  } = props

  const { dataLayer = [] } = !ssrMode ? window : {}

  const [bookmarkActive, setBookmarkActive] = useState(
    article.isBookMarked || false
  )
  const [likeActive, setLikeActive] = useState(article.isLiked || false)
  useEffect(() => {
    setLikeActive(article.isLiked)
    setBookmarkActive(article.isBookMarked)
  }, [article.isLiked, article.isBookMarked])

  useEffect(() => {
    if (page !== 'articlepage') {
      return
    }
    base.get(`/user/${article.id}`).then((response) => {
      const { success, data } = response.data
      if (success) {
        setLikeActive(data.isLiked)
        setBookmarkActive(data.isBookMarked)
      }
    })
  }, [])

  const getShareUrl = () => {
    let articleShareUrl = null

    if (customShareUrl) {
      articleShareUrl = encodeURI(customShareUrl)
    } else if (article && article.shortUrl) {
      articleShareUrl = encodeURI(article.shortUrl)
    } else {
      const shareUrl = props.shareUri
        ? `${location.origin}/${props.shareUri}`
        : location.href

      articleShareUrl = encodeURI(shareUrl)
    }

    return articleShareUrl
  }

  const getCustomPageName = () => {
    let customPageName = null

    if (subMode === 'live-article') {
      return 'livearticlepage'
    }

    if (page !== 'articlepage') {
      return customPageName
    }

    if (article.articleType === 'live') {
      customPageName = 'livearticlepage'
    } else if (article.articleType === 'sub-live') {
      customPageName = 'sublivearticlepage'
    }

    return customPageName
  }

  return (
    <Location>
      {({ location }) => (
        <SocialControls
          className={props.vertical ? 'vertical' : ''}
          shareVisible={props.visible}
          hideOnMobile={props.hideOnMobile || false}
        >
          {label && (
            <SocialLabel
              className={props.vertical ? 'labelText' : ''}
              theme={theme}
            >
              {label}
            </SocialLabel>
          )}

          <SocialControlsContainer isVerticallyAligned={isVerticallyAligned}>
            {showBookmark && (
              <SocialOption
                key={`bookmark_${article.id}`}
                id={`bookmark_${article.id}`}
                active={bookmarkActive}
                filter={props.iconFilter}
                className={themehover || ''}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  // G360 Event for Bookmark
                  dataLayer.push({
                    event: 'asEvent',
                    analyticsEventLabel: 'Content',
                    eventCategory: 'Content',
                    eventAction: 'Bookmark',
                    eventLabel: article.id,
                    publish_date: `${article.publishedAt}`
                  })

                  if (bookmarkActive) {
                    sendEventBookmark(
                      {
                        action: 'remove',
                        itemId: article.id,
                        itemType
                      },
                      getCustomPageName()
                    )
                  } else {
                    sendEventBookmark(
                      {
                        action: 'save',
                        itemId: article.id,
                        itemType
                      },
                      getCustomPageName()
                    )
                  }

                  setBookmarkActive(!bookmarkActive)
                  changeBookmark({ itemId: article.id, bookmarkActive, page })
                }}
                isVerticallyAligned={isVerticallyAligned}
              >
                {bookmarkActive ? (
                  <BookmarkIcon theme={activeTheme || 'active'} />
                ) : (
                  <BookmarkIcon theme={theme} />
                )}
              </SocialOption>
            )}
            {showLike && (
              <SocialOption
                active={likeActive}
                key={`like_${article.id}`}
                id={`like_${article.id}`}
                filter={props.iconFilter}
                className={themehover || ''}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  // G360 Event for Like
                  dataLayer.push({
                    event: 'asEvent',
                    analyticsEventLabel: 'Content',
                    eventCategory: 'Content',
                    eventAction: 'Like',
                    eventLabel: article.id,
                    publish_date: `${article.publishedAt}`
                  })

                  if (!likeActive) {
                    setLikeActive(true)
                    changeLike(true, article.id)
                    sendEventReact(
                      {
                        action: 'like',
                        itemId: article.id,
                        itemType
                      },
                      getCustomPageName()
                    )
                  } else {
                    setLikeActive(false)
                    changeLike(false, article.id)
                    sendEventReact(
                      {
                        action: 'unlike',
                        itemId: article.id,
                        itemType
                      },
                      getCustomPageName()
                    )
                  }
                }}
                isVerticallyAligned={isVerticallyAligned}
              >
                {likeActive ? (
                  <LoveIcon theme={activeTheme || 'active'} />
                ) : (
                  <LoveIcon theme={theme} />
                )}
              </SocialOption>
            )}
            <SocialOption
              key={`facebook_${article.id}`}
              id={`facebook_${article.id}`}
              filter={props.iconFilter}
              className={themehover || ''}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                // G360 Event for Facebook share
                dataLayer.push({
                  event: 'asEvent',
                  analyticsEventLabel: 'Content',
                  eventCategory: 'Social',
                  eventAction: 'Share',
                  eventLabel: 'Facebook',
                  publish_date: `${article.publishedAt || ''}`
                })

                sendEventShare(
                  {
                    platform: 'facebook',
                    itemId: article.id,
                    itemType
                  },
                  getCustomPageName()
                )

                const shareUrl = getShareUrl()

                const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`

                const options =
                  'toolbar=0,status=0,resizable=1,width=626,height=436'

                window.open(facebookUrl, 'sharer', options)
              }}
              isVerticallyAligned={isVerticallyAligned}
            >
              <FacebookIcon theme={theme} />
            </SocialOption>
            <SocialOption
              key={`twitter_${article.id}`}
              id={`twitter_${article.id}`}
              filter={props.iconFilter}
              className={themehover || ''}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                // G360 Event for Twitter share
                dataLayer.push({
                  event: 'asEvent',
                  analyticsEventLabel: 'Content',
                  eventCategory: 'Social',
                  eventAction: 'Share',
                  eventLabel: 'Twitter',
                  publish_date: `${article.publishedAt || ''}`
                })

                sendEventShare(
                  {
                    platform: 'twitter',
                    itemId: article.id,
                    itemType
                  },
                  getCustomPageName()
                )

                const shareUrl = getShareUrl()

                const twitterUrl = `https://twitter.com/intent/tweet?url=${shareUrl}&via=AsharqNews`

                window.open(twitterUrl)
              }}
              isVerticallyAligned={isVerticallyAligned}
            >
              <TwitterIcon theme={theme} />
            </SocialOption>
            {showWhatsApp ? (
              <SocialOption
                key={`whatsapp_${article.id}`}
                id={`whatsapp_${article.id}`}
                filter={props.iconFilter}
                className={themehover || ''}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  // G360 Event for Whatsapp share
                  dataLayer.push({
                    event: 'asEvent',
                    analyticsEventLabel: 'Content',
                    eventCategory: 'Social',
                    eventAction: 'Share',
                    eventLabel: 'Whatsapp',
                    publish_date: `${article.publishedAt}`
                  })

                  sendEventShare(
                    {
                      platform: 'whatsapp',
                      itemId: article.id,
                      itemType
                    },
                    getCustomPageName()
                  )

                  const encodeShareUrl = getShareUrl()

                  const whatsappUrl = `whatsapp://send?text=${encodeShareUrl}`

                  window.open(whatsappUrl)
                }}
                isVerticallyAligned={isVerticallyAligned}
              >
                <WhatsAppIcon key="whatsAppIcon" theme={theme} />
              </SocialOption>
            ) : null}
            {!ssrMode && (
              <>
                {(testMobile() || testTablet()) &&
                !ssrMode &&
                window.navigator &&
                window.navigator.share ? (
                  <SocialOption
                    key={`share_${article.id}`}
                    id={`share_${article.id}`}
                    filter={props.iconFilter}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()

                      // G360 Event for Link share
                      dataLayer.push({
                        event: 'asEvent',
                        analyticsEventLabel: 'Content',
                        eventCategory: 'Social',
                        eventAction: 'Share',
                        eventLabel: 'Link',
                        publish_date: `${article.publishedAt || ''}`
                      })

                      sendEventShare(
                        {
                          platform: 'link',
                          itemId: article.id,
                          itemType
                        },
                        getCustomPageName()
                      )

                      const shareUrl = getShareUrl()

                      window.navigator.share({
                        title: article.title,
                        text: article.description,
                        url: shareUrl
                      })
                    }}
                    isVerticallyAligned={isVerticallyAligned}
                  >
                    <ShareIcon theme={theme} />
                  </SocialOption>
                ) : (
                  <SocialOption
                    key={`email_${article.id}`}
                    id={`email_${article.id}`}
                    filter={props.iconFilter}
                    className={themehover || ''}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()

                      // G360 Event for Email
                      dataLayer.push({
                        event: 'asEvent',
                        analyticsEventLabel: 'Content',
                        eventCategory: 'Content',
                        eventAction: 'Email',
                        eventLabel: article.id,
                        publish_date: `${article.publishedAt || ''}`
                      })

                      sendEventShare(
                        {
                          platform: 'email',
                          itemId: article.id,
                          itemType
                        },
                        getCustomPageName()
                      )

                      const shareUrl = getShareUrl()

                      const mainUrl = `mailto: ?subject=${encodeURI(
                        article.title
                      )},&body=${shareUrl}`

                      window.open(mainUrl)
                    }}
                    isVerticallyAligned={isVerticallyAligned}
                  >
                    <EmailIcon key="emailIcon" theme={theme} />
                  </SocialOption>
                )}
              </>
            )}
          </SocialControlsContainer>
        </SocialControls>
      )}
    </Location>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeLike: (value, id) => {
      dispatch({ type: 'ARTICLES_CHANGE_LIKE', value, id })
    },

    changeBookmark: (value) => {
      dispatch({ type: 'ARTICLE_CHANGE_BOOKMARK', value })
    }
  }
}

export default connect(null, mapDispatchToProps)(Card)
