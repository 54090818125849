import React from "react"

import { CheckboxLabel, StyledInput, Error } from "./styles"

const ChecboxGroup = ({
  value = false,
  onChange,
  label,
  error,
  style = {},
}) => (
  <div style={style} onClick={() => onChange(!value)}>
    <StyledInput type="checkbox" checked={value} />
    <CheckboxLabel>{label}</CheckboxLabel>
    {error && <Error>{error}</Error>}
  </div>
)

export default ChecboxGroup
