import styled from 'styled-components'

export const MenuContainer = styled.div`
  overflow: hidden;
  display: flex;
  min-width: 100px;
`

export const MenuButton = styled.button`
  border: none;
  background: none;
  outline: none;
  opacity: 0.7;
  cursor: pointer;
  right: 30px;
  margin-top: 6px;

  &:hover {
    opacity: 1;
  }
`

export const MenuButtons = styled.div`
  position: ${({ isOpen }) => (isOpen ? 'absolute' : 'relative')};
  z-index: 9999;
  display: flex;
  top: ${({ isOpen }) => (isOpen ? '22px' : '0px')};
`
