import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const FontContainer = styled.div`
  color: rgb(255, 255, 255) !important;
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-size: ${({ page }) => (page && page === 'livetv' ? '21px' : '12px')};
  font-weight: bold;
  letter-spacing: 0px;
  text-align: right;
  width: 85px;
  display: inline-block;
  margin-right: ${({ page }) => (page && page === 'livetv' ? '60px' : '40px')};
  width: 100%;

  @media ${device.s} {
    font-size: 14px;
    width: 220px;
    margin-right: ${({ page }) =>
      page && page === 'livetv' ? '15px' : '30px'};
  }

  a {
    color: rgb(255, 255, 255) !important;
  }
`

export const RingContainer = styled.div`
  position: absolute;
  width: 26px;
  height: 26px;
  float: right;
  margin-right: ${({ page }) => (page && page === 'livetv' ? '30px' : '5px')};
  right: 0;

  @media ${device.s} {
    margin-right: ${({ page }) => (page && page === 'livetv' ? '0' : '10px')};
  }
`

export const Ring = styled.div`
  border: 3px solid rgba(255, 255, 255, 0.322);
  background: #c42224;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  animation: pulse 2s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
`
export const Circle = styled.div`
  width: 8px;
  height: 8px;
  background-color: #c42224;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 1px);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
`
export const SocialShareContainer = styled.div`
  width: 60%;
  float: right;
  display: flex;
  justify-content: flex-end;
  margin-left: 30px;

  @media ${device.s} {
    width: 100%;
    margin-left: 5px;
  }

  button {
    margin: 0 8px !important;
  }
`
export const CloseContainer = styled.div`
  background-color: rgb(109, 127, 135);
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
