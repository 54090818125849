import styled from "styled-components"

export const Input = styled.input`
  background: white;
  font-size: 1em;
  height: 40px;
  padding: 0 11px;
  box-sizing: border-box;
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  ${({ hasError }) => hasError && "border-color: red !important;"}
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;

  ${({ isOpen }) =>
    isOpen &&
    `    
    border-radius: 2px 2px 0 0;
`};
`

export const Container = styled.div`
  position: relative;
`

export const Overlay = styled.div`
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  background: #fff;
  max-height: 200px;
  overflow-y: scroll;
  transition: max-height 0.2s;
  z-index: 999999999;

  ${({ isOpen }) =>
    !isOpen &&
    `max-height: 0;  
  `}
}
`

export const Row = styled.div`
  background: ${({ active }) => (active ? "rgba(52, 71, 106, 0.25)" : "#fff")};
  font-size: 0.85em;
  color: #1b212c;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    background: rgba(52, 71, 106, 0.25);
  }
`

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: #818181;
  margin-bottom: 10px;
`

export const Label = styled.div`
  font-size: 1em;
`

export const StatusLabel = styled.div`
  font-size: 0.85em;
`

export const Error = styled.div`
  color: #e03c20;
  font-family: "Lucida Grande";
  font-size: 0.8em;
  margin-top: 8px;
  text-align: end;
`

export const ArrowContainer = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  justify-content: flex-end;
  top: 15px;
  padding-left: 15px;
  cursor: pointer;

  img {
    width: 12px;
    transition: transform 0.3s ease-in-out;

    ${({ isOpen }) => isOpen && `transform: rotate(180deg);`}
  }
`
