export default {
  forgot: {
    title: "إعادة ضبط كلمة السر",
    footnote: "تسجيل الدخول",
    submit: "إرسال رابط ضبط كلمة السر على البريد الإلكتروني",
  },
  login: {
    title: "تسجيل الدخول",
    footnote: "هل نسيت كلمة السر؟",
    submit: "الدخول للحساب",
  },
}
