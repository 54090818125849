import React from 'react'
import ReactPlayerLoader from '@brightcove/react-player-loader'

import LiveTvPlayerFooter from './liveTvPlayerFooter'
import testMobile from '../../utils/testMobile'

import './index.scss'

const LIVE_TV_ACCOUNT_ID = process.env.GATSBY_LIVE_TV_ACCOUNT_ID
const LIVE_TV_VIDEO_ID = process.env.GATSBY_LIVE_TV_VIDEO_ID || null
const LIVE_TV_VERTICAL_VIDEO_ID =
  process.env.GATSBY_LIVE_TV_VERTICAL_VIDEO_ID || null
const LIVE_TV_PLAYLIST_ID = process.env.GATSBY_LIVE_TV_PLAYLIST_ID || null
const LIVE_TV_PIP_PLAYER_ID = '8aR9sE33w'
const SHOW_VERTICAL_STREAM = process.env.GATSBY_SHOW_VERTICAL_STREAM || false

export default class LiveTvPlayer extends React.Component {
  orientationListener = null
  fullScreenButton = null
  refToReactPlayerLoader = React.createRef()
  webKitFullScreenEndListener = null
  // firefox, chrome and ie/edge fullscreen handler function
  events = ['fullscreenchange', 'mozfullscreenchange', 'msfullscreenchange']

  getOrientation = () => {
    let currentOrientation = 'portrait'
    if (typeof window !== 'undefined' && window.orientation !== null) {
      const orientationAngle = window.orientation
      switch (orientationAngle) {
        case -90:
        case 90:
          currentOrientation = 'landscape'
          break
        default:
          currentOrientation = 'portrait'
          break
      }
    }

    return currentOrientation
  }

  shouldShowVerticalStream = () => {
    let showVerticalStream = false
    if (SHOW_VERTICAL_STREAM && SHOW_VERTICAL_STREAM === 'true') {
      showVerticalStream = true
    }

    return showVerticalStream
  }

  state = {
    isVerticalStream: false,
    deviceOrientation: this.getOrientation()
  }

  fullScreenClickHandler = () => {
    if (this.shouldShowVerticalStream()) {
      this.setState({
        isVerticalStream: !this.state.isVerticalStream
      })
    } else {
      // set timeout to make sure the video has already entered full screen
      setTimeout(() => {
        // lock the orientation to landscape mode by default in mobile
        if (window.screen.orientation && window.screen.orientation.lock) {
          window.screen.orientation
            .lock('landscape')
            .catch((err) => console.log(err))
        }
      }, 150)
    }
  }

  handleFullScreenExist = () => {
    this.setState(
      {
        isVerticalStream: false
      },
      () => {
        setTimeout(() => {
          this.refToReactPlayerLoader.current.player.play()
        }, 500)
      }
    )
  }

  handleFullScreenEvent = () => {
    // if document fullscreen element is null, then we are existing full screen
    if (document.fullscreenElement === null) {
      this.handleFullScreenExist()
    }
  }

  onLoadSuccess = (success) => {
    const livePlayer = success.ref

    // reload video on error
    const reloadOptions = {}
    reloadOptions.errorInterval = 10 // time interval to check last error message
    livePlayer.reloadSourceOnError(reloadOptions)

    livePlayer.on('error', function (e) {
      if (livePlayer.error_) {
        livePlayer.error(null)
        e.preventDefault()
      }
    })

    if (testMobile()) {
      // get the live tv player
      const liveTvPlayer = document.querySelectorAll('.live-tv-player')
      if (liveTvPlayer && liveTvPlayer.length > 0) {
        // get the button
        const button = liveTvPlayer[0].getElementsByClassName(
          'vjs-fullscreen-control'
        )
        if (button && button.length > 0) {
          this.fullScreenButton = button[0]
          this.fullScreenButton.addEventListener(
            'touchstart',
            this.fullScreenClickHandler
          )
        }

        if (this.shouldShowVerticalStream()) {
          const video = liveTvPlayer[0].getElementsByTagName('video')

          if (video && video.length > 0) {
            // ios full screen end listener
            this.webKitFullScreenEndListener = video[0].addEventListener(
              'webkitendfullscreen',
              this.handleFullScreenExist,
              false
            )

            // handle full screen exist when back button is clicked in android
            this.events.map((event) => {
              window.addEventListener(event, this.handleFullScreenEvent, false)
            })
          }
        }
      }
    }
  }

  onOrientationChange = () => {
    const currentOrientation = this.getOrientation()
    if (currentOrientation !== this.state.deviceOrientation) {
      this.setState({
        deviceOrientation: currentOrientation
      })
    }
  }

  componentDidMount() {
    if (testMobile() && this.shouldShowVerticalStream()) {
      this.orientationListener = window.addEventListener(
        'orientationchange',
        this.onOrientationChange
      )
    }
  }

  componentWillUnmount() {
    if (this.fullScreenButton) {
      this.fullScreenButton.removeEventListener(
        'touchstart',
        this.fullScreenClickHandler
      )
    }
    if (this.orientationListener) {
      this.orientationListener.removeEventListener(
        'orientationchange',
        this.onOrientationChange
      )
    }
    if (this.webKitFullScreenEndListener) {
      this.webKitFullScreenEndListener.removeEventListener(
        'webkitendfullscreen',
        this.handleFullScreenExist
      )
    }

    if (this.shouldShowVerticalStream()) {
      this.events.map((event) => {
        window.removeEventListener(event, this.handleFullScreenEvent, false)
      })
    }
  }

  render() {
    const { mobileView = false, page, showLiveTvInPip = true } = this.props
    const { deviceOrientation, isVerticalStream } = this.state
    const newProps = LIVE_TV_PLAYLIST_ID
      ? {
          playlistId: LIVE_TV_PLAYLIST_ID
        }
      : {
          videoId:
            isVerticalStream && deviceOrientation === 'portrait'
              ? LIVE_TV_VERTICAL_VIDEO_ID
              : LIVE_TV_VIDEO_ID
        }
    return (
      <div
        style={{
          backgroundColor: '#333333',
          borderRadius: mobileView ? '0px' : '0 15px',
          width: '100%',
          height: '100%'
        }}
        className="live-tv-player"
      >
        <ReactPlayerLoader
          onSuccess={(success) => this.onLoadSuccess(success)}
          accountId={LIVE_TV_ACCOUNT_ID}
          embedOptions={{
            responsive: false,
            controls: true,
            pip: showLiveTvInPip
          }}
          ref={this.refToReactPlayerLoader}
          playerId={LIVE_TV_PIP_PLAYER_ID}
          options={{
            autoplay: true,
            playsinline: true,
            controls: true,
            muted: true
          }}
          attrs={{
            className:
              page && page === 'livetv'
                ? 'live-tv-page-video-player'
                : 'live-tv-video-player'
          }}
          manualReloadFromPropChanges={true}
          {...newProps}
        />
        <LiveTvPlayerFooter {...this.props} />
      </div>
    )
  }
}
