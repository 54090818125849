import styled from "styled-components"

export const Label = styled.div`
  font-size: 1em;
`

export const StatusLabel = styled.div`
  font-size: 0.85em;
`

export const StyledInput = styled.input`
  background: white;
  width: 100%;
  outline: none !important;
  font-size: 1em;
  height: 40px;
  padding: 0 11px;
  box-sizing: border-box;
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  ${({ hasError }) => hasError && "border-color: red !important;"}

  &:focus {
    border-color: #1b85d6;
  }
`

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: #818181;
  margin-bottom: 10px;
`

export const Error = styled.div`
  color: #e03c20;
  font-family: "Lucida Grande";
  font-size: 0.8em;
  margin-top: 8px;
  text-align: end;
`
