import { navigate } from 'gatsby'
import { connect } from 'react-redux'
import { useMatch } from '@reach/router'
import Modal from 'react-modal'
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import {
  NavigationContainer,
  NavigationUpper,
  NavigationControls,
  NavigationLogo,
  NavigationWeather,
  NavigationMenuIcon,
  NavigationLogoImg
} from './navigation.styled.js'
import { sendEventHomeIconClick } from '../../utils/sendEvent'
import LoginForm from '../forms/loginform/loginform'
import Menu from '../menu/menu'
import RegistrationForm from '../forms/registrationform/registrationform'
import Weather from './weather/weather'
import siteLogo from './siteLogo.svg'
import QuickLinks from './quicklinks'
import AsharqAppPopup from '../asharqAppPopup'

const Navigation = ({
  lang,
  closeToolbar,
  quickLinks,
  getQuickLinks,
  toggleLiveTvMobileView,
  latestNews,
  allowPopUp,
  isArticle,
  setIsAsharqPopupshown,
  articleId,
  toggleAsharqAppPopup
}) => {
  const [isRegistrationOpen, setRegistrationOpen] = useState(false)
  const [isLoginOpen, setLoginOpen] = useState(false)
  const isEnglish = useMatch('/en/*')
  const isHomePage = useMatch(isEnglish ? '/en' : '/')

  useEffect(() => {
    getQuickLinks()
  }, [])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'white',
      border: 'none'
    },
    overlay: {
      zIndex: 99,
      background: 'rgba(0,0,0,0.6)'
    }
  }

  const renderLogo = () => {
    const imgProps = {
      src: siteLogo,
      width: 123,
      alt: 'الشرق للاخبار'
    }

    if (isHomePage) {
      return (
        <Link
          to={'/'}
          onClick={async () => {
            await sendEventHomeIconClick(false)
            window.location.reload()
          }}
        >
          <NavigationLogoImg {...imgProps} alt={imgProps.alt} />
        </Link>
      )
    }

    return (
      <Link
        to={'/'}
        onClick={() => {
          closeToolbar()
          sendEventHomeIconClick(false)
        }}
      >
        <NavigationLogoImg {...imgProps} alt={imgProps.alt} />
      </Link>
    )
  }

  return (
    <NavigationContainer>
      <AsharqAppPopup
        allowPopUp={allowPopUp}
        isArticle={isArticle}
        setIsAsharqPopupshown={setIsAsharqPopupshown}
        articleId={articleId}
        toggleAsharqAppPopup={toggleAsharqAppPopup}
      />
      <NavigationUpper>
        <NavigationControls>
          <NavigationMenuIcon>
            <Menu lang={lang} />
          </NavigationMenuIcon>
        </NavigationControls>
        <NavigationLogo>{renderLogo()}</NavigationLogo>
        <NavigationWeather>
          <Weather />
        </NavigationWeather>
      </NavigationUpper>

      <QuickLinks
        data={quickLinks}
        toggleLiveTvMobileView={toggleLiveTvMobileView}
        isLatestNewsInViewport={latestNews.isLatestNewsVisible}
      />

      <Modal
        isOpen={isRegistrationOpen}
        onRequestClose={() => setRegistrationOpen(false)}
        style={customStyles}
        contentLabel="Register"
        ariaHideApp={false}
      >
        <button
          style={{ border: 'none', background: 'none', float: 'right' }}
          onClick={() => setRegistrationOpen(false)}
        >
          X
        </button>
        <RegistrationForm success={() => setRegistrationOpen(false)} />
      </Modal>

      <Modal
        isOpen={isLoginOpen}
        onRequestClose={() => setLoginOpen(false)}
        style={customStyles}
        contentLabel="Login"
        ariaHideApp={false}
      >
        <button
          style={{ border: 'none', background: 'none', float: 'right' }}
          onClick={() => setLoginOpen(false)}
        >
          X
        </button>
        <LoginForm
          success={() => setLoginOpen(false)}
          openRegistrationForm={() => {
            setLoginOpen(false)
            setRegistrationOpen(true)
          }}
        />
      </Modal>
    </NavigationContainer>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    getQuickLinks: () => {
      dispatch({ type: 'QUICK_LINKS_REQUEST' })
    },
    closeToolbar: () => {
      dispatch({ type: 'CLOSE_TOOLBAR' })
    },
    toggleLiveTvMobileView: (payload) => {
      dispatch({ type: 'SHOW_HIDE_LIVE_TV_MOBILE', payload })
    },
    toggleAsharqAppPopup: (payload) => {
      dispatch({ type: 'SHOW_HIDE_ASHARQ_APP_POPUP', payload })
    }
  }
}

const mapStateToProps = ({ quickLinks, latestNews }) => {
  return {
    quickLinks,
    latestNews
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
