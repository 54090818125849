import styled from "styled-components"
import { device } from "../../utils/screenSizes"

export const Container = styled.div`
  overflow-y: auto;
  max-height: 99vh;
  @media ${device.s} {
    max-height: 89vh;
  }
`

export const FormInputContainer = styled.div`
  padding: 10px 0;
`

export const FormInputLabel = styled.label`
  color: #999;
  padding-bottom: 10px;
  display: block;
`

export const FormTextInput = styled.input`
  background: white;
  display: block;
  width: 100%;
  outline: none;
  border: 1px solid #ccc;
  padding: 9px;
  border-radius: 2px;
  font-size: 0.9em;
  transition: all 0.3s ease-in-out;

  &:focus {
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    border: 1px solid rgba(81, 203, 238, 1);
  }
`

export const FormHeading = styled.h1`
  color: #666;
  padding: 0;
  font-size: 1.5em;
  margin: 10px 0 20px 0;
`

export const FormErrorMessage = styled.div`
  color: red;
`

export const FormButton = styled.button`
  display: inline;
  background: #52c368;
  color: #fff;
  border: none;
  border-radius: 0 10px;
  padding: 13px 22px;
  cursor: pointer;
  transition: background 0.1s ease-in;
  font-weight: bold;
  font-size: 1em;

  &:hover {
    background: #44b059;
  }
`

export const FormFootnote = styled.div`
  margin: 10px 0;
  text-align: right;

  button {
    color: #1b85d6;
    border: none;
    outline: none;
    background: none;
  }

  span {
    color: #007acc;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const FormText = styled.p`
  color: #999;
`

export const SectionTitle = styled.div`
  margin: 36px 0 18px 0;
  font-weight: bold;
  font-size: 1em;
`

export const Header = styled.div`
  background-color: #fafafa;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 45px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;

  @media ${device.s} {
    padding-left: 21px;
    padding-right: 21px;
  }

  @media ${device.s} {
    padding: 10px 21px;
  }
`

export const Title = styled.div`
  font-weight: bold;
  font-size: 1.9em;
  margin-bottom: 17px;
  color: #424242;
`

export const Subtitle = styled.div`
  color: #818181;
  font-size: 0.95em;
`

export const Close = styled.div`
  position: absolute;
  left: 3vw;
  right: 3vw;
  display: flex;
  justify-content: flex-end;
  opacity: 0.4;
  top: 40px;
  cursor: pointer;
`
export const FormContainer = styled.div`
  padding: 0 95px;

  @media (max-width: 800px) {
    padding-left: 21px;
    padding-right: 21px;
  }

  ${({ footerHeight }) => `padding-bottom: ${footerHeight + 20}px;`}
  ${({ headerHeight }) => `padding-top: ${headerHeight}px;`}
`

export const FieldsContainer = styled.div`
  display: grid;
  grid-gap: 30px 0;
`

export const Footer = styled.div`
  position: absolute;
  background: #fff;
  padding: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 45px;
  box-shadow: 0 -1px 5px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media ${device.m} {
    padding-left: 21px;
    padding-right: 21px;
  }

  @media ${device.s} {
    padding: 10px 21px;
  }
`

export const RegisterFooter = styled(Footer)`
  @media ${device.s} {
    & > div:first-child {
      margin-bottom: 15px;
    }
  }
`
