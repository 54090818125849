import styled from 'styled-components'
import { device } from '../../../utils/screenSizes'

export const WeatherContainer = styled.div`
  display: flex;
  padding-left: env(safe-area-inset-left);
  @media ${device.s} {
    justify-content: flex-end;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
`

export const FirstRow = styled(Row)`
  width: 100%;
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Space = styled.div`
  flex: 1;
`

export const WeatherName = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 1.1em;
  color: #263238;
  margin-bottom: 2px;
  margin: 4px;
  margin-right: 8px;
`

export const Condition = styled.div`
  display: flex;
  font-size: 0.9em;
  color: #575757;
  line-height: 1.1em;
  margin: 4px;
  padding-bottom: 3px;
`

export const Temperature = styled.div`
  font-family: 'Neue Haas Grotesk Bloomberg', Arial, sans-serif;
  font-size: 0.8em;
  color: #575757;
  line-height: 1.1em;
  margin: 4px;
  padding-top: 3px;
`

export const WeatherIcon = styled.div`
  flex: 1;
`
export const WeatherSvgIcon = styled.div`
  svg {
    width: 25px !important;
    max-height: 28px !important;
    margin-top: 5px;
  }
`
