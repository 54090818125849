import styled from "styled-components"

export const CheckboxLabel = styled.label`
  color: #4f4f50;
  font-size: 1em;
  margin-right: 13px;
  cursor: pointer;
`

export const StyledInput = styled.input`
  cursor: pointer;
`

export const Error = styled.div`
  color: #e03c20;
  font-family: "Lucida Grande";
  font-size: 0.8em;
  margin-top: 8px;
`
