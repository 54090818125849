import { find, isEmpty } from 'lodash'

export const extractTimeStampData = (searchText, variants) => {
  const result = []
  if (!searchText) {
    return []
  }

  const arVariant = find(variants, variant => variant.lang === 'ar')
  if (!isEmpty(arVariant)) {
    const { text, timestamps } = arVariant
    text.forEach((element, index) => {
      if (element.includes(searchText)) {
        const timestamp = timestamps[index]
        const obj = {
          text: element,
          timestamp,
          duration: timestamp.t2 - timestamp.t1
        }
        result.push(obj)
      }
    })
  }

  return result
}

const findSubText = (searchTerm, variants) => {
  const arVariant = find(variants, variant => variant.lang === 'ar')
  if (!isEmpty(arVariant)) {
    const { text } = arVariant
    const subText = find(text, element => element.includes(searchTerm))

    return subText
  }

  return ''
}

export const getCcText = (result, searchTerm) => {
  if (!isEmpty(result.variants)) {
    const ccText = findSubText(searchTerm, result.variants)
    if (!ccText) {
      const variant = result.variants[0]
      return variant.text[0]
    }

    return ccText
  }

  return ''
}
