import videojs from 'video.js'
import { isEmpty } from 'lodash'

class VideoJSMarker {
  player = {}

  init(player) {
    this.player = player
  }

  // create a non-colliding random number
  generateUUID = () => {
    var d = new Date().getTime()
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
      c
    ) {
      var r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
    return uuid
  }

  getPosition = marker => {
    return (marker.time / this.player.duration()) * 100
  }

  setMarkerDivStyle = (marker, markerDiv) => {
    const videoDuration = this.player.duration() || 180 // default it to 3 mins
    // hide out-of-bound markers
    const ratio = marker.time / videoDuration
    if (ratio < 0 || ratio > 1) {
      markerDiv.style.display = 'none'
    }

    // set position
    markerDiv.style.left = this.getPosition(marker) + '%'
    if (marker.duration) {
      markerDiv.style.width = (marker.duration / videoDuration) * 100 + '%'
      markerDiv.style.marginLeft = '0px'
    } else {
      const defaultWidth = 5
      markerDiv.style.width = defaultWidth + 'px'
      markerDiv.style.marginLeft = defaultWidth / 2 + 'px'
    }
  }

  createMarkerDiv = marker => {
    const markerDiv = videojs.dom.createEl(
      'div',
      {},
      {
        'data-marker-key': marker.key
      }
    )
    markerDiv.className = 'vjs-marker'
    this.setMarkerDivStyle(marker, markerDiv)
    return markerDiv
  }

  /**
   * Helper function to remove all the existing markers from the video player
   */
  removeAllMarkers = () => {
    if (
      isEmpty(this.player) ||
      (!isEmpty(this.player) && this.player.el && !this.player.el())
    ) {
      return
    }
    try {
      const allMarkers = this.player.el().querySelectorAll('.vjs-marker') || []
      allMarkers.forEach(marker => {
        if (marker) {
          marker.parentNode.removeChild(marker)
        }
      })
    } catch (err) {
      console.error('error while clearing markers')
    }
  }

  /**
   * Adds list of markers to the video JS player
   * @param {*} markers - array of marker objects .. marker object should have time (start time) and duration
   */
  addMarkers = markers => {
    markers.forEach(marker => {
      marker.key = this.generateUUID()
      this.player
        .el()
        .querySelector('.vjs-progress-holder')
        .appendChild(this.createMarkerDiv(marker))
    })
  }

  /**
   * Adds text tracks to the existing player
   * @param {*} textTracks - array of text track objects.. text tracks object should have start, end and text properties
   */
  addTextTracks = textTracks => {
    // remove existing text track
    if (!isEmpty(this.player)) {
      const textTrackList = this.player.textTracks()
      if (textTrackList && textTrackList.length > 0) {
        const existingTextTracks = textTrackList.tracks_ || []
        existingTextTracks.forEach(existingTextTrack =>
          this.player.removeRemoteTextTrack(existingTextTrack)
        )
      }
    }
    const textTrack = this.player.addRemoteTextTrack({
      language: 'ar',
      kind: 'subtitles',
      label: 'text tracks'
    })

    if (textTrack && textTrack.track) {
      const track = textTrack.track
      track.mode = 'showing'
      textTracks.forEach(textTrack => {
        track.addCue(new VTTCue(textTrack.start, textTrack.end, textTrack.text))
      })
    }
  }
}

const videoMarker = new VideoJSMarker()

export default videoMarker
