import { connect } from 'react-redux'
import { navigate } from 'gatsby'
import React, { useState, useEffect, useRef, useCallback } from 'react'

import { MenuContainer, MenuButton, MenuButtons } from './menu.styled'
import { sendEventSearch } from '../../utils/sendEvent'
import { useOnClickOutside } from '../../utils/useClickOutside'
import LoginForm from '../forms/loginform/loginform'
import RegistrationForm from '../forms/registrationform/registrationform'
import SideMenu from './SideMenu'
import hamburger from './hamburger.svg'
import hamburgerClose from './hamburger-close.svg'
import searchIcon from './search.svg'

const Menu = ({
  categories,
  getCategories,
  lang,
  openRegister,
  openLogin,
  closeToolbar
}) => {
  const [isOpen, setMenuOpen] = useState(false)
  const [isRegisteredUser, setIsRegisteredUser] = useState(false)

  const inputRef = useRef(null)
  const containerRef = useRef(null)
  const buttonRef = useRef(null)

  useOnClickOutside([buttonRef, containerRef], () => setMenuOpen(false))

  useEffect(() => {
    if (
      localStorage.getItem('userType') !== 'anonymous' &&
      localStorage.getItem('token')
    ) {
      // console.log("is registered...")
      setIsRegisteredUser(true)
    } else {
      // console.log("bah")
    }
  })

  useEffect(() => {
    getCategories()
  }, [getCategories])

  const onSearch = useCallback(
    (value) => {
      // G360 Event for Search
      dataLayer.push({
        event: 'asEvent',
        analyticsEventLabel: 'Search',
        eventCategory: 'Search',
        eventAction: 'Search',
        eventLabel: ''
      })
      sendEventSearch(value)
      navigate(`/${lang}/search/?query=${value}`)
      setMenuOpen(false)
      inputRef.current.blur()
    },
    [lang]
  )

  return (
    <MenuContainer dir="rtl" lang="ar">
      <MenuButtons isOpen={isOpen}>
        <MenuButton
          isOpen={isOpen}
          ref={buttonRef}
          onClick={() => {
            closeToolbar()
            setMenuOpen((value) => !value)

            // G360 Event for Menu Open/Close
            if (!isOpen) {
              dataLayer.push({
                event: 'asEvent',
                analyticsEventLabel: 'Navigation',
                eventCategory: 'Navigation',
                eventAction: 'Menu - Open',
                eventLabel: ''
              })
            } else {
              dataLayer.push({
                event: 'asEvent',
                analyticsEventLabel: 'Navigation',
                eventCategory: 'Navigation',
                eventAction: 'Menu - Close',
                eventLabel: ''
              })
            }
          }}
        >
          <img
            src={isOpen === true ? hamburgerClose : hamburger}
            id="hamburger"
            alt="Menu"
            width={20}
            loading="lazy"
          />
        </MenuButton>

        <MenuButton
          isOpen={isOpen}
          onClick={() => {
            setMenuOpen((value) => !value)
          }}
        >
          {!isOpen && (
            <img
              onClick={() => {
                closeToolbar()
                inputRef.current.focus()
              }}
              src={searchIcon}
              alt="Search"
              style={{
                width: 20,
                marginTop: 3
              }}
              loading="lazy"
            />
          )}
        </MenuButton>
      </MenuButtons>
      <SideMenu
        isOpen={isOpen}
        categories={categories.data}
        containerRef={containerRef}
        inputRef={inputRef}
        setMenuOpen={setMenuOpen}
        setLoginOpen={openLogin}
        setRegistrationOpen={openRegister}
        isRegisteredUser={isRegisteredUser}
        lang={lang}
        onSearch={onSearch}
      />

      <RegistrationForm />

      <LoginForm />
    </MenuContainer>
  )
}

const mapStateToProps = ({ categories }) => {
  return { categories }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: () => {
      dispatch({ type: 'CATEGORY_REQUESTED' })
    },
    openRegister: () => {
      dispatch({ type: 'SHOW_REGISTER' })
    },
    openLogin: () => {
      dispatch({ type: 'SHOW_LOGIN' })
    },
    closeToolbar: () => {
      dispatch({ type: 'CLOSE_TOOLBAR' })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
