import React, { useState } from 'react'
import LiveTvSearch from './search'
import LiveTvPlayerFooter from '../liveTvPlayer/liveTvPlayerFooter'
import { breakpoints } from '../../constants/app'
import useViewportSize from '../../utils/useViewportSize'
import VideoPlayer from '../videoplayer'
import { extractTimeStampData } from '../../utils/liveTvSearchHelper'

import {
  CustomVideoPlayerWrapper,
  CustomVideoPlayerInner
} from './livetv.styled'

const LiveTVHoc = (props) => {
  const {
    liveTvComponent: LiveTvComponent = null,
    showSearch = true,
    mobileView = false,
    page,
    onMobileViewClose,
    internalState = {},
    showPip = false
  } = props

  const getReplayVideoDataFromState = () => {
    if (internalState && internalState.result) {
      const searchTerm = internalState.searchTerm
      const result = internalState.result
      const markers = extractTimeStampData(searchTerm, result.variants)
      return { ...result, markers }
    }

    return null
  }

  const [replayVideo, setReplayVideo] = useState(getReplayVideoDataFromState())
  const searchTerm = internalState ? internalState.searchTerm : ''
  const { width: screenWidth } = useViewportSize()

  const playReplay = (replayVideo, searchTerm) => {
    const markers = extractTimeStampData(searchTerm, replayVideo.variants)
    setReplayVideo({ ...replayVideo, markers })
    window.scrollTo(0, 0)
  }

  const clearReplayVideo = () => {
    setReplayVideo(null)
  }

  return (
    <>
      {replayVideo ? (
        <>
          <CustomVideoPlayerWrapper>
            <CustomVideoPlayerInner>
              <VideoPlayer
                videoSrc={replayVideo.video}
                videoType={'video/mp4'}
                customHeight={screenWidth > breakpoints.m ? '562.5px' : '150px'}
                posterImage={replayVideo.image}
                markers={replayVideo.markers}
                showPip={showPip}
                goLiveButtonHandler={clearReplayVideo}
              />
            </CustomVideoPlayerInner>
            <LiveTvPlayerFooter
              showSocialShareIcons
              mobileView={mobileView}
              page={page}
              onMobileViewClose={onMobileViewClose}
            />
          </CustomVideoPlayerWrapper>
        </>
      ) : (
        <>{LiveTvComponent}</>
      )}
      {showSearch && (
        <LiveTvSearch
          searchTerm={searchTerm}
          handleReplay={playReplay}
          handleClearReplayVideo={clearReplayVideo}
        />
      )}
    </>
  )
}

export default LiveTVHoc
