import { string, object } from "yup"

const loginSchema = object().shape({
  email: string()
    .required("البريد الإلكتروني مطلوب")
    .email(" يجب أن يكون عنوان البريد الإلكتروني صالحاً"),
  password: string()
    .required("كلمة المرور مطلوبة")
    .min(8, "يجب أن تتضمن كلمة المرور 8 أحرف على الأقل"),
})

const resetSchema = object().shape({
  email: string()
    .required("البريد الإلكتروني مطلوب")
    .email(" يجب أن يكون عنوان البريد الإلكتروني صالحاً"),
})

export default { login: loginSchema, forgot: resetSchema }
