import React from "react"
import { Field } from "form"

import Input from "../input"
import ChecboxGroup from "../checkboxGroup"
import Radio from "../radio"
import Select from "../select"

const FormField = ({ component = "input", name, ...rest }) => {
  const typeToComponent = {
    input: Input,
    checkboxGroup: ChecboxGroup,
    radio: Radio,
    select: Select,
  }

  const Component =
    typeToComponent[component] || (() => <div>component not supported yet</div>)

  return <Field key={name} name={name} {...rest} render={Component} />
}

export default FormField
