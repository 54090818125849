import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { Form } from "form"

import Modal from "../../modal"
import FormField from "../formField"
import fields from "./fields"
import schema from "./schema"
import config from "./config"

import {
  FormContainer,
  FormButton,
  FormFootnote,
  Container,
  Header,
  Title,
  Close,
  FieldsContainer,
  Footer,
} from "../forms.styled"

const LoginForm = ({
  close,
  fetchingReset,
  fetching,
  isVisible,
  login,
  resetPassword,
}) => {
  const [formApi, setFormApi] = useState()
  const [mode, setMode] = useState("login")
  const [footerHeight, setFooterHeight] = useState(0)
  const [headerHeight, setHeaderHeight] = useState(0)
  const footer = useRef()
  const header = useRef()
  const pageConfig = config[mode]
  const isLoading = mode === "login" ? fetching : fetchingReset

  useEffect(() => {
    setMode("login")
    document.body.classList[isVisible ? "add" : "remove"]("prevent-scroll")
  }, [isVisible])

  useEffect(() => {
    if (footer.current) {
      setFooterHeight(footer.current.scrollHeight)
    }

    if (header.current) {
      setHeaderHeight(header.current.scrollHeight)
    }
  })

  useEffect(() => {
    if (formApi) {
      formApi.resetFields()
    }
  }, [mode])

  const handleLogin = e => {
    if (formApi.validate()) {
      const payload = {
        ...formApi.values(),
        fingerprint: localStorage.getItem("fingerprint"),
      }

      login(payload)
    }
  }

  const handleReset = e => {
    if (formApi.validate()) {
      const payload = {
        ...formApi.values(),
        fingerprint: localStorage.getItem("fingerprint"),
      }

      resetPassword(payload)
    }
  }

  const onEnter = e => {
    e.preventDefault()
    return mode === "login" ? handleLogin : handleReset
  }

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={close}
      contentLabel="Register"
      ariaHideApp={false}
    >
      <Container dir="rtl">
        <Header ref={header}>
          <Close onClick={close}>X</Close>
          <Title>{pageConfig.title}</Title>
        </Header>

        <Form formApi={setFormApi} schema={schema[mode]}>
          <FormContainer
            footerHeight={footerHeight}
            headerHeight={headerHeight}
          >
            <form onSubmit={onEnter}>
              <FieldsContainer style={{ marginTop: "35px" }}>
                {fields[mode].map(field => (
                  <FormField key={field.name} {...field} />
                ))}

                <FormFootnote>
                  <span
                    onClick={() =>
                      setMode(mode === "login" ? "forgot" : "login")
                    }
                  >
                    {pageConfig.footnote}
                  </span>
                </FormFootnote>
              </FieldsContainer>

              <Footer ref={footer}>
                <FormButton
                  disabled={isLoading}
                  onClick={mode === "login" ? handleLogin : handleReset}
                >
                  <div style={{ display: "flex" }}>
                    {pageConfig.submit}

                    {isLoading && (
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    )}
                  </div>
                </FormButton>
              </Footer>
            </form>
          </FormContainer>
        </Form>
      </Container>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    login: payload => {
      dispatch({ type: "LOGIN", payload })
    },
    resetPassword: payload => {
      dispatch({ type: "RESET", payload })
    },
    close: () => {
      dispatch({ type: "HIDE_LOGIN" })
    },
  }
}

function mapStateToProps({ login }) {
  const { isVisible, fetchingReset, fetching } = login

  return { isVisible, fetchingReset, fetching }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
