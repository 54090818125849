import styled from 'styled-components'
import { device } from '../../../utils/screenSizes'

export const Container = styled.div`
  background: rgb(255, 255, 255);
  border-radius: ${({ fromSideMenu }) => (fromSideMenu ? '0px' : '5px')};
  box-shadow: 0px 5px 23px 0px rgba(0, 0, 0, 0.17);
  margin-bottom: 30px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 999;
`

export const ShowResults = styled.div`
  height: 50px;
  padding-right: 50px;
  display: flex;
  align-items: center;
  border-bottom: 3px solid rgb(246, 246, 246);

  span {
    font-weight: bold;
    margin-right: 5px;
  }

  div {
    color: #c62326;
    font-size: 14px;
    font-family: 'Neue Haas Grotesk Bloomberg';
    text-decoration: none !important;
    border: none;
    cursor: pointer;
  }

  img {
    height: 11px;
    margin-right: 7px;
  }
`

export const Suggestions = styled.div`
  margin-top: 5px;
  border-bottom: 3px solid rgb(246, 246, 246);

  .link-class {
    color: rgb(51, 51, 51);
    font-family: 'Neue Haas Grotesk Bloomberg';
    font-size: 14px;
    font-weight: bold;
    text-decoration: none !important;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  div {
    border-bottom: 1px solid rgb(246, 246, 246);
    height: 40px;
    display: flex;
    align-items: center;
    padding-right: 24px;
  }

  .search {
    margin-left: 8px;
    width: 16px;
    height: 16px;
    margin-top: 4px;
  }

  .history {
    margin-left: 8px;
    width: 17px;
    height: 17px;
    margin-bottom: 3px;
  }
`

export const OldArticles = styled.div`
  padding: 36px 60px 35px 24px;
  display: grid;
  grid-template-columns: ${({ fromSideMenu }) =>
    fromSideMenu ? '1fr' : '1fr 1fr'};
  grid-gap: 15px 45px;

  & a {
    text-decoration: none !important;
    border: none !important;
  }

  @media ${device.s} {
    grid-template-columns: 1fr;
  }
`

export const OldArticleImage = styled.div`
  border-radius: 0px 8px;
  height: 61px;
  min-width: 72px;
  max-width: 72px;
`

export const OldArticleRow = styled.div`
  display: flex;

  .title {
    color: rgb(51, 51, 51);
    font-family: 'Neue Haas Grotesk Bloomberg';
    font-size: 15px;
    line-height: 19px;
  }

  .text-container {
    margin-left: 10px;
    width: 100%;
  }

  .date {
    color: rgb(122, 122, 122);
    font-family: 'Neue Haas Grotesk Bloomberg';
    font-size: 14px;
    text-decoration: none !important;
    border: none;
  }
`
