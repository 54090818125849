import React from 'react'
import { Ring, Circle, LiveIconContainer, Label } from './liveIcon.styled'

const LiveIcon = props => {
  const { extraClass = '', label, isDarkLabel = false } = props
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      {label && <Label isDarkLabel={isDarkLabel}>{label}</Label>}
      <LiveIconContainer className={extraClass}>
        <Ring />
        <Circle />
      </LiveIconContainer>
    </div>
  )
}

export default LiveIcon
