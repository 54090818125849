import styled from "styled-components"

export const Label = styled.div`
  font-size: 1em;
`

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: #818181;
  margin-bottom: 14px;
`

export const Error = styled.div`
  color: #e03c20;
  font-family: "Lucida Grande";
  font-size: 0.8em;
  margin-top: 8px;
  text-align: end;
`

export const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 120px);
  grid-gap: 22px 0;
`

export const CheckboxLabel = styled.label`
  color: #4f4f50;
  font-size: 1em;
  margin-right: 13px;
  cursor: pointer;
`

export const StyledInput = styled.input`
  cursor: pointer;
  display: block;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const StatusLabel = styled.div`
  font-size: 0.85em;
`
