import { string, object, array, boolean } from "yup"

const schema = object().shape({
  firstName: string().required("الاسم الأول مطلوب"),
  lastName: string().required("الاسم الأخير مطلوب"),
  email: string()
    .required("البريد الإلكتروني مطلوب")
    .email("يجب أن يكون عنوان البريد الإلكتروني صالحاً"),
  password: string()
    .required("كلمة المرور مطلوبة")
    .min(8, "يجب أن تتضمن كلمة المرور 8 أحرف على الأقل"),
  loveCategories: array().required("اختر فئة واحدة على الأقل"),
  ageRange: string().required("إدخال العمر مطلوب"),
  gender: string().required("إدخال الجنس مطلوب"),
  agreed: boolean().test(
    "is-agreed",
    "تجب الموافقة على الشروط للاستمرا",
    value => !!value
  ),
  // country: string().nullable(),
  // maritalStatus: string().nullable(),
  // noChildren: string().nullable(),
  // monthlyIncome: string().nullable(),
  // languages: string().nullable(),
  // occupation: string().nullable(),
})

export default schema
