import React, { useEffect } from 'react'

import videojs from 'video.js'

import VideoJSMarker from './video-js-marker'

import './index.scss'
import './videojs.scss'

const VideoPlayer = ({
  videoSrc,
  videoType,
  customHeight = 'auto',
  posterImage,
  markers = [],
  showPip = false,
  showTextTracks = false,
  goLiveButtonHandler = () => {}
}) => {
  let player = null
  const createGoLiveBtn = () => {
    const checkButtonExists = document.getElementById('go-live-btn')

    if (!checkButtonExists) {
      const goLiveButton = document.createElement('div')
      goLiveButton.id = 'go-live-btn'
      goLiveButton.className = 'vjs-control go-live-btn'
      goLiveButton.innerHTML = '<span>GO LIVE</span>'
      goLiveButton.onclick = () => {
        goLiveButtonHandler()
      }
      const controlBar = document.getElementsByClassName('vjs-control-bar')[0]

      // Change the class name here to move the icon in the controlBar
      const insertBeforeNode = document.getElementsByClassName(
        'vjs-fullscreen-control'
      )[0]

      controlBar.insertBefore(goLiveButton, insertBeforeNode)
    }
  }

  const initVideoJSPlayer = (player, markersData, textTracksData) => {
    try {
      // video started playing
      VideoJSMarker.init(player)
      VideoJSMarker.addMarkers(markersData)
      if (showTextTracks) {
        VideoJSMarker.addTextTracks(textTracksData)
      }

      // add "Go Live Button"
      createGoLiveBtn()
    } catch (err) {
      console.error('error while initialising video js player', err)
    }
  }

  useEffect(() => {
    try {
      const video = document.getElementById('livetv_replay_video')

      if (video) {
        player = videojs(document.querySelector(`#livetv_replay_video`), {
          controls: true,
          autoplay: false,
          width: 'auto',
          height: customHeight,
          bigPlayButton: false,
          poster: posterImage,
          muted: true,
          controlBar: {
            pictureInPictureToggle: showPip
          }
        })
        player.src(videoSrc)
        player.on('loadeddata', () => {
          VideoJSMarker.removeAllMarkers()
          const el = player.play()
          const markersData = []
          const textTracksData = []
          markers.forEach((marker) => {
            markersData.push({
              time: marker.timestamp.t1,
              duration: marker.duration
            })
            textTracksData.push({
              start: marker.timestamp.t1,
              end: marker.timestamp.t2,
              text: marker.text
            })
          })
          // for some browser this will be undefined
          if (el) {
            el.then(() => {
              initVideoJSPlayer(player, markersData, textTracksData)
            })
          } else {
            setTimeout(() => {
              initVideoJSPlayer(player, markersData, textTracksData)
            }, 200)
          }
        })
        player.on('error', () => {
          console.log(`Error while creating video ${videoSrc}`, player.error())
        })
      }
    } catch (error) {
      console.log(`Error while creating video ${videoSrc}`)
    }
  }, [videoSrc])

  useEffect(() => {
    return () => {
      // displose player when component is getting unmounted
      if (player && player.dispose) {
        player.dispose()
      }
    }
  }, [])

  if (!videoSrc && !videoType) {
    return null
  }

  return (
    <div className="html5_video_player">
      <div className="video-js">
        <video id="livetv_replay_video" className="vjs-default-skin"></video>
      </div>
    </div>
  )
}

export default VideoPlayer
