import styled from 'styled-components'

export const PopupContainer = styled.div`
  width: 100%;
  height: 90px;
  background-color: #efefef;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: row;
`
export const CloseIconContainer = styled.div`
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const TextContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 65px;
    height: 65px;
  }
`
export const LinkBtnContainer = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 10px;
`
export const Text = styled.span`
  color: #000000;
  font-size: 15px;
  line-height: 19px;
  padding: 0 10px;
  font-weight: bold;
  font-family: Neue Haas Grotesk Bloomberg;
`
export const AppLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: #c62326;
  border-radius: 0 15px 0 15px;
  font-size: 14px;
  padding: 10px;
  border: 2px solid #c62326;
  font-weight: bold;
  background-color: #ffffff;
`
