const loginFields = [
  { name: "email", label: "البريد الإلكتروني", required: true },
  { name: "password", label: "كلمة السر", required: true, type: "password" },
]

const resetFields = [
  { name: "email", label: "البريد الإلكتروني", required: true },
]

export default { login: loginFields, forgot: resetFields }
