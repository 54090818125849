import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import searchIcon from './search.svg'
import clearIcon from './clear-icon.svg'

const SearchContainer = styled.div`
  display: flex;
  height: 46px;
  background-color: #eceff1;
  display: flex;
  padding-right: 30px;

  &:focus {
    outline: none;
  }
`

const IconWrapper = styled.div`
  margin-top: 13px;
  cursor: pointer;
`
const Icon = styled.img`
  width: 24px;
  height: 24px;
`
const InputWrapper = styled.div`
  flex: 1;
  padding-top: 3px;
  margin-right: 16px;
  margin-left: 16px;
`
const Input = styled.input`
  width: 100%;
  border: 0;
  outline: none;
  font-size: 1.25em;
  line-height: 40px;
  color: #263238;
  background-color: #eceff1;
  padding: 0;
`
const SearchInput = ({
  value,
  onChange,
  inputRef,
  placeholder,
  onSearch,
  onBlur,
  onFocus,
  onClear
}) => {
  const [isOnFocus, setIsOnFocus] = useState(false)

  const filterSearch = value => {
    if (value) onSearch(inputRef.current.value)
  }

  return (
    <SearchContainer isOnFocus={isOnFocus}>
      <IconWrapper onClick={() => filterSearch(inputRef.current.value)}>
        <Icon src={searchIcon} alt="search" />
      </IconWrapper>
      <InputWrapper>
        <Input
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              filterSearch(event.target.value)
            }
          }}
          onBlur={() => {
            setIsOnFocus(false)

            if (onBlur) {
              onBlur()
            }
          }}
          onFocus={() => {
            setIsOnFocus(true)

            if (onFocus) {
              onFocus()
            }
          }}
        />
        <img
          onClick={onClear}
          style={{
            position: 'absolute',
            left: '15px',
            top: '15px',
            cursor: 'pointer',
            WebkitTapHighlightColor: 'transparent'
          }}
          src={clearIcon}
          alt="clear"
          loading="lazy"
        />
      </InputWrapper>
    </SearchContainer>
  )
}

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired,
  onClear: PropTypes.func.isRequired
}

export default SearchInput
