import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const SocialControls = styled.div`
  display: flex;
  align-items: center;
  color: white;
  height: auto;
  width: ${(props) => props.width || 'auto'};
  opacity: ${({ shareVisible }) => (shareVisible ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;

  ${({ hideOnMobile }) =>
    hideOnMobile &&
    `
    @media ${device.s} {
      display: none;
    }
  `};

  @media (hover: none) {
    opacity: 1;
  }

  &.vertical {
    flex-flow: row wrap;
    padding: 12px 18px;
  }
`

export const SocialOption = styled.button`
  border: none;
  outline: none;
  background: none;
  padding: ${({ isVerticallyAligned }) =>
    isVerticallyAligned ? '15px 4px 0' : '0 4px'};
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
  margin: 0 3px;

  filter: ${(props) => (props.filter ? props.filter : 'unset')};

  &:hover {
    opacity: 1;
  }

  &.midgrey {
    &:hover {
      svg {
        filter: brightness(0.46);
      }
    }
  }

  img {
    margin: 0;
    padding: 0;
    opacity: 1;
  }

  @media ${device.s} {
    padding: 0;
    margin: 0 12px;
  }

  ${({ active }) =>
    active &&
    `
    opacity: 1;
  `}
`

export const CardShareDivider = styled.span`
  height: 100%;
  padding: 0 15px;
`

export const SocialLabel = styled.span`
  padding: 0 10px;
  color: ${({ theme }) => (theme === 'light' ? 'rgb(255, 255, 255)' : '#777')};

  font-size: 17px;

  @media (max-width: 500px) {
    color: rgb(51, 51, 51);
    font-size: 15px;
    padding: 0px;
  }

  &.labelText {
    font-family: 'Neue Haas Grotesk Bloomberg';
    color: rgb(51, 51, 51);
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
  }
`
export const SocialControlsContainer = styled.div`
  padding: ${({ isVerticallyAligned }) =>
    isVerticallyAligned ? '0 0 0 25px' : '0'};
  display: ${({ isVerticallyAligned }) =>
    isVerticallyAligned ? 'flex' : 'block'};
  flex-direction: column;
`

export const SocialControlsContainerAuthor = styled.div`
  width: 100%;
  border-bottom: 1px solid #ececec;
  line-height: 2;

  @media ${device.s} {
    text-align: center;
  }
`
export const TwitterHandle = styled.span`
  color: rgb(108, 108, 108);
  font-size: 13px;
  padding-left: 5px;
`
export const IconContainer = styled.div`
  width: 16px;
  height: 18px;
`
export const InActiveContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-size: 13px;
  font-weight: bold;
  align-items: center;
  height: 30px;
  cursor: pointer;
  margin-top: 20px;

  @media ${device.s} {
    margin: 20px 0;
  }
`
export const ActiveContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  margin-top: 20px;
  font-size: 13px;

  @media ${device.s} {
    margin: 20px 0;
  }
`
