const ImgixClient = require('imgix-core-js')
const fromEntries = require('object.fromentries')

const signImgix = (url, author = false) => {
  try {
    const oldUrl = new URL(url)
    const client = new ImgixClient({
      domain: oldUrl.host,
      secureURLToken: author
        ? process.env.GATSBY_IMGIX_KEY_AUTHOR
        : process.env.GATSBY_IMGIX_KEY
    })
    const entries = fromEntries(oldUrl.searchParams.entries())
    return client.buildURL(oldUrl.pathname, entries)
  } catch (e) {
    return null
  }
}

module.exports = signImgix
