import { Link, navigate } from 'gatsby'
import React, { useState, useEffect, useRef } from 'react'
import { useMatch } from '@reach/router'

import PropTypes from 'prop-types'

import {
  MenuContent,
  MenuSearch,
  MenuItems,
  MenuCategories,
  MenuCategory,
  MenuBackground,
  MenuIcons,
  MenuItem,
  CategoryTitle,
  SideMenuTitle,
  SNSIcon,
  SNSIcons,
  SideMenuTitleLine,
  LineContainer,
  MobileLogo,
  MobileLogoContainer,
  MobileSNSIcons,
  MobileSNSsContainer
} from './SideMenu.styled'
import { sendEventHomeIconClick, sendEventSearch } from '../../utils/sendEvent'
import SearchInput from './SearchInput'
import aboutIcon from './about.svg'
import homeIcon from './home.svg'
import logoutIcon from './logout.svg'
import youtube from './youtube.svg'
import facebook from './facebook.svg'
import twitter from './twitter.svg'
import instagram from './instagram.svg'
import linkedin from './linkedin.svg'
import privacyIcon from './privacy.svg'
import registerIcon from './register.svg'
import siteLogo from '../../components/menu/siteLogo.svg'
import termsIcon from './terms.svg'
import userIcon from './user.svg'
import frequencyIcon from './frequency.svg'
import careersIcon from './careersMenu.svg'
import sitemapIcon from './sitemap.svg'
import presentersIcon from './presenters.svg'

import SearchResults from '../search/Results'

const SideMenu = ({
  isOpen,
  categories,
  containerRef,
  inputRef,
  setMenuOpen,
  setLoginOpen,
  setRegistrationOpen,
  isRegisteredUser,
  animationTime,
  onSearch
}) => {
  const categoryRef = useRef(null)
  const [startBackgroundTransition, setStartBackgroundTransition] =
    useState(false)
  const [showBackground, setShowBackground] = useState(false)
  const [menuCategoriesHeight, setMenuCategoriesHeight] = useState(500)
  const [searchText, setSearchText] = useState('')
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    setMenuCategoriesHeight(
      window.innerHeight - categoryRef.current.offsetTop - 80
    )
  }, [])

  useEffect(() => {
    if (isOpen) {
      setStartBackgroundTransition(true)
    }
  }, [animationTime, isOpen])

  useEffect(() => {
    if (startBackgroundTransition) {
      const timeout = setTimeout(() => {
        setShowBackground(true)
      }, 1)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [startBackgroundTransition])

  useEffect(() => {
    if (!isOpen) {
      setShowBackground(false)
      const timeout = setTimeout(() => {
        setStartBackgroundTransition(false)
      }, animationTime * 1000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isOpen, animationTime])

  useEffect(() => {
    document.body.classList[isOpen ? 'add' : 'remove']('prevent-scroll')
  }, [isOpen])

  const sendSearchEvent = (searchTerm) => {
    sendEventSearch(searchTerm)
  }

  const isHomePage = useMatch('/')

  return (
    <>
      <MenuBackground
        startBackgroundTransition={startBackgroundTransition}
        showBackground={showBackground}
        animationTime={animationTime}
      />
      <MenuContent
        ref={containerRef}
        isOpen={isOpen}
        animationTime={animationTime}
      >
        <MenuSearch>
          <MobileLogoContainer>
            <MobileLogo
              src={siteLogo}
              alt="الشرق للاخبار"
              onClick={async () => {
                setMenuOpen(false)
                if (isHomePage) {
                  await sendEventHomeIconClick(true)
                  window.location.reload()
                } else {
                  sendEventHomeIconClick(true)
                  navigate('/')
                }
              }}
            />
          </MobileLogoContainer>

          <SideMenuTitle>منصات التواصل الاجتماعي</SideMenuTitle>

          <LineContainer>
            <SideMenuTitleLine />
          </LineContainer>

          <SNSIcons>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://www.facebook.com/asharqnews"
            >
              <SNSIcon src={facebook} alt="facebook" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://twitter.com/Asharqnews"
            >
              <SNSIcon src={twitter} alt="twitter" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://www.instagram.com/asharqnews/"
            >
              <SNSIcon src={instagram} alt="instagram" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://www.youtube.com/c/asharqnews"
            >
              <SNSIcon src={youtube} alt="youtube" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://www.linkedin.com/company/asharqnews/"
            >
              <SNSIcon src={linkedin} alt="linkedin" />
            </a>
          </SNSIcons>

          <div style={{ position: 'relative' }}>
            <SearchInput
              inputRef={inputRef}
              placeholder={'بحث'}
              value={searchText || ''}
              onChange={(e) => setSearchText(e.target.value)}
              onSearch={(value) => {
                setSearchText('')
                onSearch(value)
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => {
                setTimeout(() => {
                  setIsFocused(false)
                }, 200)
              }}
              onClear={() => setSearchText('')}
            />

            <SearchResults
              closeModal={() => setMenuOpen(false)}
              visible={isFocused && searchText && searchText.length}
              fromSideMenu
              search={searchText}
              debouncedSendEventSearch={sendSearchEvent}
            />
          </div>
        </MenuSearch>

        <MenuItems>
          <MenuItem>
            <MenuIcons src={homeIcon} alt="home" />

            <Link
              to={'/'}
              onClick={() => {
                setMenuOpen(false)
                // G360 Event for home menu click
                dataLayer.push({
                  event: 'asEvent',
                  analyticsEventLabel: 'Navigation',
                  eventCategory: 'Navigation',
                  eventAction: 'Menu - Section Click',
                  eventLabel: 'الصفحة الرئيسية'
                })
              }}
            >
              الصفحة الرئيسية
            </Link>
          </MenuItem>

          {!isRegisteredUser ? (
            <>
              <MenuItem>
                <MenuIcons src={userIcon} alt="user" />
                <div
                  className="link"
                  onClick={(e) => {
                    // G360 Event for login menu click
                    dataLayer.push({
                      event: 'asEvent',
                      analyticsEventLabel: 'Navigation',
                      eventCategory: 'Navigation',
                      eventAction: 'Menu - Section Click',
                      eventLabel: 'الدخول للحساب'
                    })
                    setLoginOpen(true)
                    setMenuOpen(false)
                  }}
                >
                  الدخول للحساب
                </div>
              </MenuItem>

              <MenuItem>
                <MenuIcons src={registerIcon} alt="register" />
                <div
                  className="link"
                  onClick={(e) => {
                    // G360 Event for register menu click
                    dataLayer.push({
                      event: 'asEvent',
                      analyticsEventLabel: 'Navigation',
                      eventCategory: 'Navigation',
                      eventAction: 'Menu - Section Click',
                      eventLabel: 'إنشاء حساب'
                    })
                    setRegistrationOpen(true)
                    setMenuOpen(false)
                  }}
                >
                  إنشاء حساب
                </div>
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem>
                <MenuIcons src={userIcon} alt="profile" />
                <Link
                  to={'/profile/'}
                  onClick={() => {
                    setMenuOpen(false)
                    // G360 Event for profile click
                    dataLayer.push({
                      event: 'asEvent',
                      analyticsEventLabel: 'Navigation',
                      eventCategory: 'Navigation',
                      eventAction: 'Menu - Section Click',
                      eventLabel: 'ملف تعريفي للمستخدم'
                    })
                  }}
                >
                  ملف تعريفي للمستخدم
                </Link>
              </MenuItem>

              <MenuItem>
                <MenuIcons src={logoutIcon} alt="logout" />
                <div
                  className="link"
                  onClick={() => {
                    // G360 Event for logout
                    dataLayer.push({
                      event: 'asEvent',
                      analyticsEventLabel: 'Navigation',
                      eventCategory: 'Navigation',
                      eventAction: 'Menu - Section Click',
                      eventLabel: 'تسجيل خروج'
                    })

                    setMenuOpen(false)

                    localStorage.removeItem('token')
                    localStorage.removeItem('userType')

                    window.location.reload()
                  }}
                >
                  تسجيل خروج
                </div>
              </MenuItem>
            </>
          )}
          <MenuItem>
            <MenuIcons src={careersIcon} alt="careers" />
            <a
              href="https://careers.asharq.com/"
              target="_blank"
              rel="noopener noreferrer nofollow"
              onClick={() => {
                setMenuOpen(false)
                // G360 Event for careers
                dataLayer.push({
                  event: 'asEvent',
                  analyticsEventLabel: 'Navigation',
                  eventCategory: 'Navigation',
                  eventAction: 'Menu - Section Click',
                  eventLabel: 'الوظائف'
                })
              }}
            >
              الوظائف
            </a>
          </MenuItem>
          <MenuItem>
            <MenuIcons src={privacyIcon} alt="privacy" />
            <Link
              to={'/ar/privacy-policy/'}
              onClick={() => {
                setMenuOpen(false)
                // G360 Event for privacy policy
                dataLayer.push({
                  event: 'asEvent',
                  analyticsEventLabel: 'Navigation',
                  eventCategory: 'Navigation',
                  eventAction: 'Menu - Section Click',
                  eventLabel: 'الخصوصية'
                })
              }}
            >
              الخصوصية
            </Link>
          </MenuItem>

          <MenuItem>
            <MenuIcons src={termsIcon} alt="terms and conditions" />
            <Link
              to={'/ar/terms-conditions/'}
              onClick={() => {
                setMenuOpen(false)
                // G360 Event for Terms and conditions
                dataLayer.push({
                  event: 'asEvent',
                  analyticsEventLabel: 'Navigation',
                  eventCategory: 'Navigation',
                  eventAction: 'Menu - Section Click',
                  eventLabel: 'الشروط'
                })
              }}
            >
              الشروط
            </Link>
          </MenuItem>
          <MenuItem>
            <MenuIcons src={aboutIcon} alt="about" />
            <Link
              to={'/ar/about/'}
              onClick={() => {
                setMenuOpen(false)
                // G360 Event for About us
                dataLayer.push({
                  event: 'asEvent',
                  analyticsEventLabel: 'Navigation',
                  eventCategory: 'Navigation',
                  eventAction: 'Menu - Section Click',
                  eventLabel: 'عن الشرق'
                })
              }}
            >
              عن الشرق
            </Link>
          </MenuItem>
          <MenuItem>
            <MenuIcons src={frequencyIcon} alt="platforms" />
            <Link
              to={'/platforms/'}
              onClick={() => {
                setMenuOpen(false)
                // G360 Event for Platforms
                dataLayer.push({
                  event: 'asEvent',
                  analyticsEventLabel: 'Navigation',
                  eventCategory: 'Navigation',
                  eventAction: 'Menu - Section Click',
                  eventLabel: 'منصاتنا'
                })
              }}
            >
              منصاتنا
            </Link>
          </MenuItem>
          <MenuItem>
            <MenuIcons src={presentersIcon} alt="فريق الشرق" />
            <Link
              to={'/فريق-الشرق/'}
              onClick={() => {
                setMenuOpen(false)
                // G360 Event for Platforms
                dataLayer.push({
                  event: 'asEvent',
                  analyticsEventLabel: 'Navigation',
                  eventCategory: 'Navigation',
                  eventAction: 'Menu - Section Click',
                  eventLabel: 'فريق الشرق'
                })
              }}
            >
              {'فريق الشرق'}
            </Link>
          </MenuItem>
          <MenuItem hideBottomBorder>
            <MenuIcons src={sitemapIcon} alt="sitemap" />
            <Link
              to={'/sitemap/'}
              onClick={() => {
                setMenuOpen(false)
                // G360 Event for sitemap
                dataLayer.push({
                  event: 'asEvent',
                  analyticsEventLabel: 'Navigation',
                  eventCategory: 'Navigation',
                  eventAction: 'Menu - Section Click',
                  eventLabel: 'خريطة الموقع'
                })
              }}
            >
              خريطة الموقع
            </Link>
          </MenuItem>
        </MenuItems>

        <MobileSNSsContainer>
          <MobileSNSIcons>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://www.facebook.com/asharqnews"
            >
              <SNSIcon src={facebook} alt="facebook" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://twitter.com/Asharqnews"
            >
              <SNSIcon src={twitter} alt="twitter" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://www.instagram.com/asharqnews/"
            >
              <SNSIcon src={instagram} alt="instagram" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://www.youtube.com/c/asharqnews"
            >
              <SNSIcon src={youtube} alt="youtube" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://www.linkedin.com/company/asharqnews/"
            >
              <SNSIcon src={linkedin} alt="linkedin" />
            </a>
          </MobileSNSIcons>
        </MobileSNSsContainer>

        <CategoryTitle>التصنيفات</CategoryTitle>
        <MenuCategories className="safari" ref={categoryRef}>
          {categories
            .filter((category) => category.showInMenu)
            .map((category, index) => (
              <MenuCategory
                showBottomLine={categories.length === index + 1}
                key={`category${index}`}
              >
                {category.slug.indexOf('http') > -1 ? (
                  <a
                    href={encodeURI(category.slug)}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                    onClick={() => {
                      setMenuOpen(false)
                      // G360 Event for Category click
                      dataLayer.push({
                        event: 'asEvent',
                        analyticsEventLabel: 'Navigation',
                        eventCategory: 'Navigation',
                        eventAction: 'Menu - Section Click',
                        eventLabel: category.ar
                      })
                    }}
                  >
                    {category.ar}
                  </a>
                ) : (
                  <Link
                    to={encodeURI(category.slug)}
                    onClick={() => {
                      setMenuOpen(false)
                      // G360 Event for Category click
                      dataLayer.push({
                        event: 'asEvent',
                        analyticsEventLabel: 'Navigation',
                        eventCategory: 'Navigation',
                        eventAction: 'Menu - Section Click',
                        eventLabel: category.ar
                      })
                    }}
                  >
                    {category.ar}
                  </Link>
                )}
              </MenuCategory>
            ))}
          <MenuCategory showBottomLine={false}></MenuCategory>
        </MenuCategories>
      </MenuContent>
    </>
  )
}

SideMenu.defaultProps = {
  categories: [],
  animationTime: 0.5
}

SideMenu.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({ ar: PropTypes.string, en: PropTypes.string })
  ),
  animationTime: PropTypes.number,
  onSearch: PropTypes.func.isRequired
}

export default SideMenu
