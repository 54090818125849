import React, { useState, useRef, useEffect } from 'react'
import {
  Input,
  Overlay,
  Container,
  Row,
  Label,
  Error,
  LabelContainer,
  StatusLabel,
  ArrowContainer
} from './styles'
import arrowDown from './arrow.png'

export default ({
  value = '',
  error,
  onChange,
  label,
  items = [],
  required,
  searchable = false
}) => {
  const input = useRef(null)
  const arrow = useRef(null)
  const hasError = !!error

  const [isOpen, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  let filtered = [...items]

  if (searchable && searchValue.length) {
    filtered = filtered.filter(({ label, translations = [] }) => {
      translations = translations.map(translation => translation.toLowerCase())
      translations = [...translations, label.toLowerCase()]

      return translations.some(translation =>
        translation.includes(searchValue.toLowerCase())
      )
    })
  }

  useEffect(() => {
    const valueLabel = items.find(item => item.value === value)?.label || ''

    setSearchValue(valueLabel)
  }, [value])

  const handleClick = e => {
    const isDropdownRow = e.target.classList.contains('dropdown-row')
    const clickedInput =
      input.current &&
      (input.current.contains(e.target) || e.target.tagName === 'INPUT')
    const clickedArrow = arrow.current && arrow.current.contains(e.target)

    if (isDropdownRow || clickedInput || clickedArrow) {
      return
    }

    if (searchable) {
      const newValue = items.find(
        ({ label }) => label.toLowerCase() === searchValue.toLowerCase()
      )

      if (!newValue) {
        const valueLabel = items.find(item => item.value === value)?.label || ''
        setSearchValue(valueLabel)
      } else {
        onChange(newValue.value)
      }
    }

    setOpen(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [value, searchValue, items.length])

  return (
    <div>
      <LabelContainer>
        <Label>{label}</Label>
        {required && <StatusLabel>مطلوب</StatusLabel>}
      </LabelContainer>

      <Container>
        <ArrowContainer
          ref={e => (arrow.current = e)}
          onClick={() => setOpen(!isOpen)}
          isOpen={isOpen}
        >
          <img src={arrowDown} alt="arrow down" loading="lazy" />
        </ArrowContainer>

        <Input
          readOnly={!searchable}
          ref={inp => (input.current = inp)}
          onClick={() => {
            setOpen(searchable ? true : !isOpen)

            if (input.current) {
              input.current.focus()
            }
          }}
          hasError={hasError}
          isOpen={isOpen}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />

        <Overlay isOpen={isOpen}>
          {filtered.map(item => (
            <Row
              key={item.value}
              active={value === item.value}
              onClick={() => {
                onChange(item.value)
                setOpen(false)
              }}
            >
              {item.label}
            </Row>
          ))}
        </Overlay>
      </Container>

      {error && <Error>{error}</Error>}
    </div>
  )
}
