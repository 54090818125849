import styled from 'styled-components'
import { device } from '../../../utils/screenSizes'

export const QuickLinkContainer = styled.div`
  cursor: pointer;
  flex-grow: 1;
  text-align: center;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  -webkit-tap-highlight-color: transparent;

  @media ${device.s} {
    height: 40px;
  }

  a,
  .live-tv {
    padding: 0 20px;
    margin: 20px 0;
    border-right: 1px solid rgb(236, 236, 236);
    text-decoration: none;
    color: rgb(51, 51, 51);
    font-size: 14px;

    @media ${device.s} {
      white-space: nowrap;
      padding: 0 10px;
      height: 30px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    span {
      vertical-align: middle;
    }
  }

  a:first-child {
    border: 0;
  }

  div:first-child {
    border: 0;
  }

  ${(props) =>
    props.shouldShowScroll &&
    `
    @media ${device.s} {
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: scroll;
      overflow-y: hidden;
      scroll-behavior: smooth;
      width: calc(100% - 60px);
      padding-left: 60px;
      margin-left: 45px;
      margin-right: 10px;
    }

    a:last-child {
      padding-left: 45px;
    }  

    &:after {
      display: block;
      width: 45px;
      height: 36px;
      content: '';
      position: absolute;
      left: -2px;
      top: auto;
      z-index: 1;
      background: transparent linear-gradient(90deg, #ffffff 30%, #ffffff00 100%)
        0% 0% no-repeat padding-box;
    }
  `}
`

export const LiveTvMobileView = styled.div`
  background: rgb(255, 255, 255);
  box-shadow: 0px 5px 23px 0px rgba(0, 0, 0, 0.17);
  margin-bottom: 30px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 998;
  text-align: right;

  .live-tv-video-player {
    height: 80% !important;

    @media ${device.s} {
      height: 150px !important;
    }
  }

  .live-tv-player {
    height: 200px !important;
  }

  .player-footer {
    @media ${device.s} {
      height: 50px !important;
    }
  }
`

export const SpinnerContainer = styled.div`
  width: 100%;
`
