import styled from 'styled-components'
import { device } from '../../../utils/screenSizes'

export const SearchBarContainer = styled.div`
  background-color: #1c1c1c;
  height: auto;
  width: 100%;

  .clearIcon {
    position: absolute !important;
    left: 15px;
    top: 10px;
    cursor: pointer;
  }
`
export const SearchArea = styled.div`
  padding: 20px 17px 18px 20px;

  @media ${device.s} {
    padding: 15px 17px 18px 15px;
  }
`

export const SearchText = styled.span`
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;

  @media ${device.s} {
    font-size: 13px;
  }
`
export const SearchInput = styled.input`
  color: rgb(79, 79, 80);
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  border: none;
  outline: none;
  padding: 0 15px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 20px;
`

export const SearchContainer = styled.div`
  border-radius: 0 15px 0 0;
  height: 625px;
  background-color: #2a2a2a;
  display: block;

  @media ${device.s} {
    width: 100%;
    height: calc(100% - 79px) !important;
    position: relative;
  }

  // when the mobile device is in landscape, there is not enough space to show the search container
  @media screen and (orientation: landscape) and (min-aspect-ratio: 13/9) and (min-width: 319px) and (max-width: 900px) {
    display: none;
  }
`

export const SearchInputContainer = styled.div`
  background: #fff;
  border-radius: 20px;
  height: 40px;
  position: relative;
  margin-top: 10px;
  padding-left: 30px;

  .search {
    position: absolute;
    left: 16px;
    top: 12px;
    width: 16px;
    height: 16px;
  }

  .clear {
    position: absolute;
    left: 15px;
    top: 15px;
    cursor: pointer;
  }
`

export const SearchResultsContainer = styled.div`
  height: 505px;
  overflow-x: hidden;
  overflow-y: auto;
  direction: ltr; // for scrollbar to appear on the right hand side

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #474747;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #9a9a9a;
  }

  @media ${device.s} {
    height: calc(100% - 140px);
    z-index: 1005 !important;
    overflow: auto;
    display: none;
  }
`

export const SearchResultsDefaultState = styled.div`
  padding: 10px;
  text-align: center;
  overflow: hidden auto;
  margin: 150px auto auto auto;

  @media ${device.s} {
    margin: 35px auto auto auto;
  }
`

export const DefaultStateText = styled.span`
  color: #ffffff;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  margin: 150px auto auto auto;
`

export const SearchResultItem = styled.div`
  display: flex;
  flex: 1;
  height: 140px;
  margin: 9px 19px 0 22px;
  border-bottom: 1px solid rgba(229, 229, 229, 0.12);
  flex-direction: column;
  direction: rtl;
  cursor: pointer;
  z-index: 1005;

  @media ${device.s} {
    margin: 9px 30px 0 11px;
    height: 136px;
  }
`
export const ResultDate = styled.span`
  font-size: 12px;
  color: #c61f1c;
  font-weight: 700;
  font-family: 'Neue Haas Grotesk Bloomberg';
  margin-top: 10px;

  @media ${device.s} {
    font-size: 14px;
  }
`
export const ResultTitle = styled.span`
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  max-height: 28px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin-top: 10px;
`
export const ResultBody = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`
export const CCImage = styled.img`
  width: 18px;
  height: 12px;
  margin-left: 5px;
  margin-top: 4px;
`
export const CCText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  vertical-align: middle;

  @media ${device.s} {
    font-size: 13px;
  }
`
export const PlayVideoButton = styled.div`
  border: 1px solid #c61f1c;
  margin-top: 10px;
  width: 100px;
  height: 25px;
  display: flex;
  border-radius: 50px;
  align-items: center;
  cursor: pointer;
  span {
    font-size: 10px;
    color: #ffffff;
    font-weight: 400;
    padding: 0 10px 3px 0;
  }
  img {
    justify-content: flex-end;
  }
`
export const ReplayImageContainer = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #c61f1c;
  top: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`
export const ReplayImageWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

export const NoResults = styled.div`
  text-align: center;
  color: #666;
  padding: 20px 0;
  margin: 150px auto auto auto;

  @media ${device.s} {
    margin: 0;
  }
`
export const SearchTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
