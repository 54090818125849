import React from "react"

import {
  Error,
  Label,
  ItemsContainer,
  LabelContainer,
  CheckboxLabel,
  StyledInput,
  Container,
  StatusLabel,
} from "./styles"

const ChecboxGroup = ({
  name,
  value = [],
  onChange,
  label,
  error,
  items = [],
  required,
}) => {
  return (
    <div>
      <LabelContainer>
        <Label>{label}</Label>
        {required && <StatusLabel>مطلوب</StatusLabel>}
      </LabelContainer>

      <ItemsContainer>
        {items.map(item => (
          <Container
            key={item.value}
            onClick={() => {
              const current = value.includes(item.value)
              const checked = !current

              onChange(
                checked
                  ? [...value, item.value]
                  : value.filter(v => v !== item.value)
              )
            }}
          >
            <StyledInput
              type="checkbox"
              checked={value.includes(item.value)}
              onChange={() => {}}
            />
            <CheckboxLabel>{item.label}</CheckboxLabel>
          </Container>
        ))}
      </ItemsContainer>

      {error && <Error>{error}</Error>}
    </div>
  )
}

export default ChecboxGroup
